import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IVerificationType } from '../models/verification-type.model';

@Injectable({
  providedIn: 'root'
})
export class VerificationTypeService {
  private url: string = environment.API_URL + '/VerificationType';

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<IVerificationType[]>(`${this.url}`);
  }

}
