import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeleteModel } from 'src/app/shared/models/delete.model';
import { environment } from 'src/environments/environment';
import { IUserPhoto } from '../models/user-photo.model';
import { IUserViewModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserPhotoService {

  private url: string = environment.API_URL + '/UserPhoto';

  constructor(private http: HttpClient) { }
  add(userPhoto: IUserPhoto) {
    return this.http.post(this.url, userPhoto);
  }

  getAll() {
    return this.http.get(this.url);
  }

  getById(id: number): Observable<IUserPhoto[]> {
    return this.http.get<IUserPhoto[]>(`${this.url}/${id}`);
  }

  update(userPhoto: IUserPhoto) {
    return this.http.put(`${this.url}/${userPhoto.id}`, userPhoto);
  }

  getUserPhotos(userId: number): Observable<IUserPhoto[]> {
    return this.http.get<IUserPhoto[]>(`${this.url}/GetUserPhotoes?UserId=${userId}`)
  }

  deleteUserPhotos(deleteModel: DeleteModel) {
    return this.http.post(`${this.url}/UpdateMultipleStatus`, deleteModel);
  }

  updateUploadUserImages(userPhoto: IUserPhoto) {
    return this.http.put(`${this.url}/${userPhoto.id}`, userPhoto);
  }
  
  uploadUserImages(userPhoto: IUserPhoto) {
    return this.http.post<IUserPhoto>(`${this.url}`, userPhoto);
  }
  updateMultipleStatus(param: any) {
    return this.http.post(`${this.url}/UpdateMultipleStatus`, param);
  }
  getUsers(adminId: number): Observable<IUserViewModel[]> {
    const usersURL = environment.API_URL + '/Users';
    return this.http.get<IUserViewModel[]>(`${usersURL}?UserId=${adminId}`)

  }

  updateVerification(ids: number[], status: boolean) {
    const url = environment.API_URL + '/Users/UpdateVerificationApprovedFlag';

    let param = {
      "userIds": ids,
      "isVerificationApproved": status
    };

    return this.http.post(url, param);
  }
}
