import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class LanguageTranslatorService {

  private language = environment.supportedLanguage[0].value;
  private headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.azureTranslatorKey)
    .set('Ocp-Apim-Subscription-Region', environment.azureRegion);
  private url = environment.azureUrl;

  constructor(private http: HttpClient) {
    if (localStorage.getItem('language')) {
      this.language = localStorage.getItem('language')!;
    }
  }

  translateText(input: string): Observable<string> {
    if (this.language == 'en') {
      return of(input);
    }
    let body = [
      {
        "text": input
      }
    ]
    return this.http.post<string>(this.url + this.language, body, { headers: this.headers })
      .pipe(map((data: any) => data[0]["translations"][0].text), catchError(err => {
        return of(input);
      }));
  }

  setLanguage(language: string) {
    localStorage.setItem('language', language);
  }

}