export class IStytchToken {
  session: IStytchSession;
  session_token: string;
  user_id: string
  phone: string;
}

export class IStytchModal {
  phone: string;
  user_ID : string;
  nickname : string;
  email : string;
  picture: string;
}

export interface IStytchSession {
  expires_at: string, last_accessed_at: string, session_id: string
}
