import { Component, OnInit } from '@angular/core';
import { CanonicalService } from '../../services/canonical.service';
import { HelpDeskService } from '../../services/helpdesk.service';
import { IFaqPrivacyModel } from '../models/faq-privacy.model';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.scss']
})
export class HelpdeskComponent implements OnInit {
  helpdesks: IFaqPrivacyModel[] = [];

  constructor(private helpDeskService: HelpDeskService,private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.helpDeskService.getAll().subscribe((response) => {
      this.helpdesks = response;
    }, (error) => {
      console.error(error);
    })
  }

}
