import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICommonUser } from 'src/app/core/models/common-user.model';
import { TaskService } from 'src/app/features/tasks/services/task.service';
import { IUserNotificationResponse } from 'src/app/features/user-notification/models/user-notification-reponse.model';
import { UserService } from 'src/app/features/users/services/user.service';
import { LanguageTranslatorService } from 'src/app/shared/services/language-translator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  languages = environment.supportedLanguage;
  notificationList: any[] = [];
  notificationsExists: boolean = false;
  imageUrl: string = environment.loggedInImage;
  isActive: boolean = false;

  @Input() isAuthenticated: boolean = false;
  @Input() currentUser!: ICommonUser;
  @Input() userNotification!: IUserNotificationResponse;


  @Output() onSignOut: EventEmitter<void> = new EventEmitter();
  @Output() onLogin: EventEmitter<void> = new EventEmitter();
  @Output() onClickedUserNotification: EventEmitter<void> = new EventEmitter();


  constructor(private languageTranslatorService: LanguageTranslatorService,
    private _sanitizer: DomSanitizer, private taskSvc: TaskService, private service:UserService,
              private router: Router) { }

  ngOnInit(): void {
    this.getUserImage(this.currentUser.userId);
  }

  changeLanguage(language: any) {
    if (environment.isLanguageTranslatorEnabled) {
      this.languageTranslatorService.setLanguage(language.value);
      window.location.reload();
    }
  }

  getNotifications() {
    this.onClickedUserNotification.emit();
  }

  getUserImage(userId: number) {
    this.service.getProfileDetails(userId).subscribe((res: any) => {
      if (res.image) {
        this.imageUrl = res.image;
      } else {
        this.imageUrl = '/assets/img/loggedin.png';
      }
    });
  }
  notify() {
    let data: Array<any> = [];
    data.push({
      'title': 'Approval',
      'alertContent': 'This is First Alert -- By Debasis Saha'
    });
    data.push({
      'title': 'Request',
      'alertContent': 'This is Second Alert -- By Debasis Saha'
    });
    data.push({
      'title': 'Leave Application',
      'alertContent': 'This is Third Alert -- By Debasis Saha'
    });
    data.push({
      'title': 'Approval',
      'alertContent': 'This is Fourth Alert -- By Debasis Saha'
    });
    data.push({
      'title': 'To Do Task',
      'alertContent': 'This is Fifth Alert -- By Debasis Saha'
    });
    // this._notificationService.generateNotification(data);
  }

  login() {
    this.onLogin.emit();
  }

  toggleSearch(val: boolean) {
    this.isActive = val;
  }

  navigateProfile() {
    this.router.navigate(['profile']);
  }
  navigateInvoice() {
    this.router.navigate(['invoice']);
  }

  lockScreen() { }

  signOut() {
    this.onSignOut.emit();
  }

  myValueFormatter = (data: any) => {
    let html = `${data['data']}`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  onSelectTask(data: any) {
    this.toggleSearch(false);
    if (typeof (data) === 'object' && data.id !== 0) {
      let queryString = '';

      if (data.group === 'Task') {
        queryString = 'taskTitle=' + data.name.replace(/<[^>]*>/g, '');
      } else if (data.group === 'Category') {
        queryString = 'category=' + data.name.replace(/<[^>]*>/g, '');
      }
      window.location.href = `/my-tasks?${queryString}`;
    }
  }

  observableSource = (keyword: any): Observable<any[]> => {
    if (keyword) {
      return this.taskSvc.getSearchDetails(keyword)
        .pipe(
          map(res => {

            if (res.length == 0) return [];

            let result = [];
            result.push({
              id: 0,
              group: '',
              name: 'Task',
              data: `<label class="ngui-label">Task</label>`
            });
            for (let i = 0; i < res.length; i++) {
              const element = res[i];
              if(element.name.length > 54) element.name = element.name.substring(0,54) + '...';
              if (element.type === 'Task') {
                result.push({
                  id: element.id,
                  group: 'Task',
                  name: element.name,
                  data: `<a class="ngui-a">` + element.name + `</a>`
                });
              }
            }
            result.push({
              id: 0,
              group: '',
              name: 'Category',
              data: `<label class="ngui-label">Category</label>`
            });
            for (let i = 0; i < res.length; i++) {
              const element = res[i];
              if(element.name.length > 54) element.name = element.name.substring(0,54) + '...';
              if (element.type === 'Category') {
                result.push({
                  id: element.id,
                  group: 'Category',
                  name: element.name,
                  data: `<a class="ngui-a">` + element.name + `</a>`
                });
              }
            }
            return result;
          })
        )
    } else {
      return of([]);
    }
  }

  onPrint() {
    window.print();
  }
}
