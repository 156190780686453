import { Injectable } from '@angular/core';

import {  BehaviorSubject } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/messaging';

@Injectable({
  providedIn: 'root'
})
export class AdminPushNotificationService {




  currentMessage = new BehaviorSubject(null);
  constructor() {
    firebase.messaging().onMessage((msg: any) => {
      this.currentMessage.next(msg);
    });
  }

  requestPermission() {
    firebase.messaging().getToken().then((token) => {
    });
  }

  receiveMessage() {
    firebase.messaging().onMessage((msg: any) => {
      this.currentMessage.next(msg);
    });
  }

}
