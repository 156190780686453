import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() collectionSize: number = 0;
  @Input() page: number = 1;
  @Input() pageSize: number = 1;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  get showFrom() {
    return (this.pageSize * this.page) - (this.pageSize - 1);
  }
  get showTo() {
    return (this.pageSize * this.page) > this.collectionSize ? this.collectionSize : (this.pageSize * this.page);
  }

  onPageChanged(page: number) {
    this.pageChange.emit(page);
  }

}
