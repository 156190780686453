<div>
    <div id="headerWrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 text-center mb-5">
                    <a href="/home" class="navbar-brand-privacy">
                        <img src="/assets/img/logo.png" class="img-fluid"
                            alt="logo">
                    </a>
                    <a href="/" class="back-button" style="transform: translate(-100%,-23%);"><i class="fa fa-chevron-left"></i></a>
  
                  </div>
                <div class="col-md-12 col-sm-12 col-12 text-center">
                    <h2 class="main-heading">Near Me</h2>
                </div>
            </div>
        </div>
    </div>
  
    <div id="privacyWrapper" class="">
        <div class="privacy-container">
            <div class="privacyContent">
  
                <div class="d-flex justify-content-between privacy-head">
                    <div class="privacyHeader">
                        <h1>Near Me</h1>
                        <p>coming soon...</p>
                    </div>
                  </div>
                  <div class="privacy-content-container">
                      <section>
                          <h1>coming soon...</h1>
                          
                      </section>
                    </div>
                  
              </div>
              
          </div>
      </div>
  </div>
  <br><br><br>
  
  <footer class="index-footer">
    <div class="container text-center">
      <div class="links">
        <a href="/about-us">About Us</a>
        <a href="/privacy">Privacy Policy</a>
        <a href="/team">Team</a>
        <a href="/user-feedback">Share Feedback</a>
        <a href="/helpdesk">Self Helpdesk</a>
        <a href="/faq">FAQ</a>
        <a href="/contact-us">Contact Us</a>
        <a href="/term-condition"> Terms and Conditions</a>
        <a href="/near-me">Near Me </a>
      </div>
      <div class="social-links">
        <a href="#"><i class="fab fa-twitter"></i></a>
        <a href="#"><i class="fab fa-facebook"></i></a>
        <a href="#"><i class="fab fa-youtube"></i></a>
        <a href="#"><i class="fab fa-linkendin"></i></a>
      </div>
      <span class="copyrights">© 2020 BUYWORK ALL RIGHTS RESERVED.</span>
      <div style="margin: 10px 0;"> 
      <img src="assets/img/visa.jpg" class="footer-img">
      <img src="assets/img/Mastercard_logo.jpg" class="footer-img">
      <img src="assets/img/maestro.jpg" class="footer-img">
      <img src="assets/img/Rupay.png" class="footer-img">
      <img src="assets/img/Net banking.jpg" class="footer-img">
      <img src="assets/img/Paytm-Logo.jpg" class="footer-img">
      <img src="assets/img/upi.png" class="footer-img">
      <img src="assets/img/discover.png" class="footer-img">
      <img src="assets/img/100-secure.jpg" class="footer-img">
      </div>
    </div>
  </footer>
  
    
  
  
  