import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {IOnlineUser} from "../models/online-user.model";
import {IChatRoom} from "../models/chat-room.model";
import {IChat} from "../models/chat.model";
import firebase from 'firebase/app';
import 'firebase/database';
import {from, Observable, Subscriber} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private CHATS = '/group_chat';
  private USERS = '/online_users';
  private CHAT_ROOMS = '/chat_rooms';

  private STATUS = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE'
  };

  private FIREBASE = 'FIREBASE_KEY';
  private chatsRef: any;
  private chatRoomsRef: any;
  private usersRef: any;

  constructor() {
    this.chatsRef = firebase.database().ref(this.CHATS);
    this.chatRoomsRef = firebase.database().ref(this.CHAT_ROOMS);
    this.usersRef = firebase.database().ref(this.USERS);
  }

  addChatRoom(room: any) {
    this.chatRoomsRef.push(room).then((_: any) => {
      console.log('new chat room added.');
    }
    ).catch((err: any) => {
    });
  }

  getChatRooms(): Observable<IChatRoom[]> {
    return  new Observable((observer: Subscriber<IChatRoom[]>) => {
      firebase.database().ref(this.CHAT_ROOMS).on('value', (changes) => {
        observer.next(changes.val() ? Object.keys(changes.val()).map(c =>  ({ key: c, ...changes.val()[c]} as IChatRoom)) : []);
      });
      });

  }

  updateChatRoom(key: string, newText: string) {
    this.chatRoomsRef.update(key, { roomName: newText });
  }

  deleteChatRoom(key: string) {
    this.chatRoomsRef.remove(key);
  }

  addChat(chat: any) {
    this.chatsRef.push(chat).catch((err: any) => {


    });
  }

  addChatByUser(chat: any, chatRoom: string, username?: string, currentRoom?: string) {
    const chatsRef = firebase.database().ref(chatRoom);

    chatsRef.push(chat).then(() => {
      this.updateOnlineUser(username, currentRoom);
    }).catch((err: any) => {


    });
  }

  getChatsInChatRoom(chatRoom: string): Observable<IChat[]> {
    return  new Observable((observer: Subscriber<IChat[]>) => {
      firebase.database().ref(this.CHATS).orderByChild('roomName').equalTo(chatRoom).on('value', (changes) => {
        observer.next(changes.val() ? Object.keys(changes.val()).map(c =>  ({ key: c, ...changes.val()[c]} as IChat)) : []);
      });
    });
  }

  getChatsInByUser(chatRoom: string) : Observable<IChat[]> {
    return  new Observable((observer: Subscriber<IChat[]>) => {
      firebase.database().ref(chatRoom).on('value', (changes) => {
        observer.next(changes.val() ? Object.keys(changes.val()).map(c =>  ({ key: c, ...changes.val()[c]} as IChat)) : []);
      });
    });
  }

  getOnlineUsers(): Observable<IOnlineUser[]> {
    return  new Observable((observer: Subscriber<IOnlineUser[]>) => {
        // @ts-ignore
      firebase.database().ref(this.USERS).on('value', (changes) => {
          observer.next( changes.val() ?  Object.keys(changes.val()).map(c =>  ({ key: c, ...changes.val()[c]} as IOnlineUser)) : [])
        });
    });
  }

  onlineUsersListener() {
      return  new Observable((observer: Subscriber<IOnlineUser>) => {
        firebase.database().ref(this.USERS).on('child_changed', (changes) => {
          observer.next( { key: changes.key, ...changes.val()});
        });
      });
  }
  addOnlineUser(user: any) {
    const onlineUserRef  = firebase.database().ref(this.USERS).orderByChild('userName').equalTo(user.userName).limitToFirst(1);
    onlineUserRef.once('value').then(res => {
      if (res.val() === null) {
        this.usersRef.push(user).catch((err: any) => {
        });
      } else {
        res.forEach((client: any) => {
          localStorage.setItem(this.FIREBASE, client.key);
        });
      }
    });
  }


   updateOnlineUser(username: string, roomname: string) {
    const onlineUserRef =  firebase.database().ref(this.USERS).orderByChild('userName').equalTo(username).limitToFirst(1);
    onlineUserRef.once('value').then(res => {
      res.forEach(data => {
        const user = data.val();
        if(user.chats) {
          user.chats[roomname] = {unread: true, counter: user.chats[roomname] ? user.chats[roomname].counter + 1 : 1 }
        }else {
          user.chats = [];
          user.chats[roomname] = {unread: true, counter: 1};
        }
        firebase.database().ref(this.USERS).child(data.key).set(user);
      });
    });
  }

  updateOnlineUserStatus(key: string, status: string) {
    this.usersRef.update(key, { status: status }).catch(console.log);
  }

  setOffline() {
    if (localStorage.getItem(this.FIREBASE)) {
      this.updateOnlineUserStatus(localStorage.getItem(this.FIREBASE), this.STATUS.OFFLINE);
    }
  }

  setOnline() {
    if (localStorage.getItem(this.FIREBASE)) {
      this.updateOnlineUserStatus(localStorage.getItem(this.FIREBASE), this.STATUS.ONLINE);
    }
  }

  resetSmsCounter(username: string, roomname: string) {
    const onlineUserRef =  firebase.database().ref(this.USERS).orderByChild('userName').equalTo(username).limitToFirst(1);
    onlineUserRef.once('value').then(res => {
      res.forEach(data => {
        const user = data.val();
        if(user.chats && user.chats[roomname] && user.chats[roomname].unread) {
          user.chats[roomname] = {unread: false, counter: 0}
          firebase.database().ref(this.USERS).child(data.key).set(user);
        }
      });
    });
  }
}
