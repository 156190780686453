import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {Meta, Title} from "@angular/platform-browser";
import  firebase from "firebase/app";
import 'firebase/messaging'
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  message:any = null;


  title = 'buyWork';

  constructor(private router: Router, private titleService: Title, private metaService: Meta, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.setMetaTags();
   // this.requestPermission();
   // this.listen();
  }

  requestPermission() {
    const messaging = firebase.messaging().getToken().then(
      (currentToken) => {
        if (currentToken) {
          console.log("Hurraaa!!! we got the token.....");
          console.log(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }
  listen() {
    firebase.messaging().onMessage( (payload) => {
      console.log('Message received. ', payload);
      this.message= payload;
    });
  }

  setMetaTags() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {

        const rt = this.getChild(this.route);

        rt.data.subscribe(data => {
          this.titleService.setTitle(data.title);

          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption });
          } else {
            this.metaService.removeTag("name='description'");
          }

          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots });
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" });
          }

          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl });
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url });
          }

          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }

          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }

          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }


        });
      });
  }

  private getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
}
