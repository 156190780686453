import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeleteModel } from 'src/app/shared/models/delete.model';
import { environment } from 'src/environments/environment';
import { ITaskCategory } from '../models/task-category.model';

@Injectable({
  providedIn: 'root'
})
export class TaskCategoryService {

  private url: string = environment.API_URL + '/TaskCategories';


  constructor(private http: HttpClient) { }

  addTaskCategory(taskCategory: ITaskCategory) {
    return this.http.post(this.url, taskCategory);
  }

  updateTaskCategory(taskCategory: ITaskCategory) {
    return this.http.put(`${this.url}/${taskCategory.categoryId}`, taskCategory);
  }

  deleteTaskCategory(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  deleteMultipleTaskCategories(deleteModel: DeleteModel) {
    return this.http.post(`${this.url}/multiple-delete-categories`, deleteModel);
  }

  getTaskCategories(): Observable<ITaskCategory[]> {
    return this.http.get<ITaskCategory[]>(this.url);
  }
  getTaskCategoriesOnlyActive(): Observable<ITaskCategory[]> {

    return this.http.get<ITaskCategory[]>(this.url + '/GetActive');
  }


  getTaskCategory(id: number): Observable<ITaskCategory> {
    return this.http.get<ITaskCategory>(`${this.url}/${id}`);
  }
}
