import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { ICommonUser } from 'src/app/core/models/common-user.model';
import { AuthStorageService } from 'src/app/core/services/auth-storage.service';
import { ToastService, TOAST_TYPE } from 'src/app/core/services/toast.service';
import { IUserPhoto } from 'src/app/features/user-photo/models/user-photo.model';
import { UserPhotoService } from 'src/app/features/user-photo/services/user-photo.service';
import { environment } from 'src/environments/environment';
import { FileSizeHelper } from '../../helpers/file-size.helper';
import { DeleteModel } from '../../models/delete.model';
import { IVerificationType } from '../../models/verification-type.model';
import { VerificationTypeService } from '../../services/verification-type.service';

@Component({
  selector: 'verification-modal',
  templateUrl: './verification-modal.component.html',
  styleUrls: ['./verification-modal.component.scss']
})
export class VerificationModalComponent implements OnInit, OnChanges, OnDestroy {
  modalRef!: NgbModalRef;
  subscription: Subscription[] = [];
  currentUser!: ICommonUser;
  userImages!: IUserPhoto[];
  uploadImages: IUserPhoto;

  verificationTypes$!: Observable<IVerificationType[]>;
  verificationType: number = 1;

  allowedFileType = environment.AllowedFileType;
  deleteModel: DeleteModel = {
    ids: [],
    isActive: false
  };

  imageIndex: number = 0;
  imageId: number = 0;

  removeImageModalRef!: NgbModalRef;

  @Input() isActive: boolean;
  @Output() onClosedModal: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('content') content!: ElementRef;
  @ViewChild('removeImageEl') removeImageRef!: ElementRef;
  @ViewChild('file') file: ElementRef;

  constructor(private modalService: NgbModal,
    private authStorageSvc: AuthStorageService,
    private userPhotoSvc: UserPhotoService,
    private verificationTypeSvc: VerificationTypeService,
    private toastSvc: ToastService

  ) { }


  ngOnInit(): void {
    this.currentUser = this.authStorageSvc.getCommonUserInfo();
    this.verificationTypes$ = this.verificationTypeSvc.getAll();
    this._getDocuments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive && changes.isActive.currentValue) {
      this.modalRef = this.modalService.open(this.content, {
        backdrop: 'static',
        keyboard: false,
        centered: true
      })
    } else {
      if (this.modalRef) {
        this.modalRef.close();
        this.onClosedModal.emit(false);
      }
    }
  }

  removeImage(index: number, imageId: number) {
    this.imageIndex = index;
    this.imageId = imageId;

    this.removeImageModalRef = this.modalService.open(this.removeImageRef, {
      backdrop: 'static',
      keyboard: false,
      centered: true
    })

  }

  defaultUserPhoto() {
    this.uploadImages = {
      id: 0,
      userId: this.currentUser?.userId,
      photo: '',
      verificationTypeId: 0,
      isActive: true,
      createdBy: this.currentUser?.userId,
      createdDate: new Date(),
      updatedBy: this.currentUser?.userId,
      updatedDate: new Date()
    };
  }

  onFileUpload(uploadedFiles: any) {
    for (let index = 0; index < uploadedFiles.target.files.length; index++) {
      const fileNam = uploadedFiles.target.files[index].name;
      this.defaultUserPhoto();
      if (this._validateFileExtension(uploadedFiles.target.files[index].name)) {
        if (FileSizeHelper.validateFileSize(uploadedFiles.target.files[index].size, 2)) {
          this._fileReadBase64String(uploadedFiles.target.files[index], this.uploadImages);
          this.userImages.push(this.uploadImages);
        }
        else {
          this.toastSvc.show(TOAST_TYPE.ERROR, 'Error', 'Image size must not be greater than 2MB');
        }
      }
      else {
        this.toastSvc.show(TOAST_TYPE.ERROR, 'Error', environment.SizeErrorMessage);
      }
    }
  }

  onSubmit() {
    if (this.userImages.length > 0) {
      if (this.userImages.length <= 3) {
        if (this.verificationType) {
          let counter = 0;
          this.userImages.forEach((element, index) => {
            element.verificationTypeId = this.verificationType;
            if (element.id > 0) {
              element.isActive = true;
              element.updatedBy = this.currentUser?.userId;
              element.updatedDate = new Date();
              element.createdBy = this.currentUser?.userId;
              element.createdDate = new Date();
              element.userId = this.currentUser?.userId;
              this.userPhotoSvc.updateUploadUserImages(element).subscribe((result) => {
                counter = counter + 1;
                if (counter == this.userImages.length) {
                  this.modalRef.close();
                  this.isActive = false;
                  this.onClosedModal.emit(false);
                  this.toastSvc.show(TOAST_TYPE.SUCCESS, "Success", "Image Uploaded Successfully");
                }

              }, (error) => {
                this.toastSvc.show(TOAST_TYPE.ERROR, 'Error', 'Failed to save image');
              });
            }
            else {
              this.userPhotoSvc.uploadUserImages(element).subscribe((result) => {
                counter = counter + 1;
                if (counter == this.userImages.length) {
                  this.modalRef.close();
                  this.isActive = false;
                  this.onClosedModal.emit(false);
                  this.toastSvc.show(TOAST_TYPE.SUCCESS, "Success", "Image Uploaded Successfully");
                }

              }, (error) => {
                this.toastSvc.show(TOAST_TYPE.ERROR, 'Error', 'Failed to save image');

              });
            }
          });
        } else {
          this.toastSvc.show(TOAST_TYPE.ERROR, 'Error', 'Please select verification type');
        }
      }
      else {
        this.toastSvc.show(TOAST_TYPE.ERROR, 'Error', 'You can allow to upload maximum 3 images');
      }
    }
    else {
      this.toastSvc.show(TOAST_TYPE.ERROR, 'Error', 'No Image selected/uploaded');
    }
  }

  onConfirmImageDelete() {
    if (this.removeImageModalRef)
      this.removeImageModalRef.close();

    this.deleteModel.ids = [];
    if (this.imageId != 0) {
      this.deleteModel.ids.push(this.imageId);
      this.userPhotoSvc.deleteUserPhotos(this.deleteModel).subscribe((result) => {
        this.userImages.splice(this.imageIndex, 1);
        this.toastSvc.show(TOAST_TYPE.SUCCESS, 'Success', 'Image deleted successfully');
      }, (error) => {
        this.toastSvc.show(TOAST_TYPE.ERROR, 'Error', 'Failed to delete image');
      });
    }
    else {
      this.userImages.splice(this.imageIndex, 1);
      this.file.nativeElement.value = "";
    }
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
      this.isActive = false;
      this.onClosedModal.emit(false);

    }
  }

  private _getDocuments() {
    this.subscription.push(
      this.userPhotoSvc.getUserPhotos(this.currentUser?.userId).subscribe((data) => {
        this.userImages = data;
      }, error => {
        this.toastSvc.show(TOAST_TYPE.ERROR, "Error", "something went wrong while getting User Document");
      })
    );
  }

  private _fileReadBase64String(file: any, userPhoto: IUserPhoto) {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      userPhoto.photo = e.target.result;
    }
    reader.readAsDataURL(file);
  }
  private _validateFileExtension(name: string) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    let isExist = this.allowedFileType.find(element => element === ext?.toLowerCase())
    if (isExist) {
      return true;
    }
    else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.forEach(x => x.unsubscribe());
  }

}
