import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreferencesNotifierService {
  preferences = new BehaviorSubject<string[]>([]);
  receivedData = this.preferences.asObservable();
  constructor() { }

  updatePreferences(data: string[]){
    this.preferences.next(data);
   }
}
