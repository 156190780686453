import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAboutUsModel } from '../models/AboutUsModel';


@Injectable({
  providedIn: 'root'
})
export class AboutUsService {

  private readonly url: string = environment.API_URL + '/pages/terms-of-service';


  constructor(private http: HttpClient) { }

  getAll(): Observable<IAboutUsModel[]> {
    return this.http.get<IAboutUsModel[]>(this.url);
  }
}
