import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUser } from '../models/user.model';
import { catchError, map } from 'rxjs/operators';

import { throwError as observableThrowError } from 'rxjs/index';
import { ISkillModel } from '../../user/_models/skill.model';
import { DeleteModel } from "../../../shared/models/delete.model";
import { IPlatform } from "../../user/_models/platform.model";
import { IDeletedTransaction } from '../../transaction-type/_models/update-status';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url: string = environment.API_URL + '/Users';
  private skillurl: string = environment.API_URL + '/Skills';
  private educationurl: string = environment.API_URL + '/Education';
  private userSkillurl: string = environment.API_URL + '/UserSkill';
  private deleteSkillUrl: string = environment.API_URL + '/UserSkill/multiple-delete-user-skills';
  private workPlatformurl: string = environment.API_URL + '/WorkPlateform';
  private deleteWorkPlatformUrl: string = environment.API_URL + '/WorkPlateform/multiple-delete-user-work-platform';
  private userPreferenceUrl: string = environment.API_URL + '/UserPreference';
  

  profileDetails$: any;
  skillsTasksDetails$: any;


  constructor(private http: HttpClient) { }

  add(model: IUser) {
    let form = new FormData();
    form.append('UserName', model.userName);
    form.append('Email', model.email);
    form.append('LastName', model.lastName);
    form.append('FirstName', model.firstName);
    form.append('Location', model.location);
    form.append('PhoneNumber', model.phoneNumber);
    form.append('City', model.city);
    form.append('State', model.state);
    form.append('Country', model.country);
    form.append('Address', model.address);
    form.append('Createdby', model.createdby.toString());
    form.append('CreatedOn', model.createdOn.toUTCString());
    form.append('UpdatedBy', model.updatedBy.toString());
    form.append('UpdatedOn', new Date().toUTCString());
    form.append('IsActive', model.isActive.toString());

    return this.http.post(this.url, form);
  }

  update(model: IUser) {
    let form = new FormData();
    form.append('Id', model.id.toString());
    form.append('UserName', model.userName);
    form.append('Email', model.email);
    form.append('LastName', model.lastName);
    form.append('FirstName', model.firstName);
    form.append('Location', model.location);
    form.append('PhoneNumber', model.phoneNumber);
    form.append('City', model.city);
    form.append('State', model.state);
    form.append('Country', model.country);
    form.append('Address', model.address);
    form.append('Createdby', model.createdby.toString());
    form.append('CreatedOn', new Date(model.createdOn).toUTCString());
    form.append('UpdatedBy', model.updatedBy.toString());
    form.append('UpdatedOn', new Date().toUTCString());
    form.append('IsActive', model.isActive.toString());
    return this.http.put(`${this.url}/${model.id}`, form);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  getAll(): Observable<IUser[]> {
    return this.http.get<IUser[]>(this.url);
  }

  get(id: number): Observable<IUser> {
    return this.http.get<IUser>(`${this.url}/${id}`);
  }

  getProfileDetails(id: any): Observable<any> {

    return this.http.get(this.url + '/' + id)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }
  
  private extractData(res: Response): Response | [] {
    const body = res;
    return body || [];
  }

  public handleError(error: Response | any): Response | any {
    return observableThrowError(error);
  }

  getDetails(id: any): Observable<any> {
    return this.http.get(this.url + '/GetUserInformation?UserId=' + id)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }
  getCompletedTask(id:any): Observable<any>{
    return this.http.post(this.url + '/CompletedTask', id)
        .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }
  getAllSkills(): Observable<ISkillModel[]> {
    return this.http.get<ISkillModel[]>(this.skillurl);
  }

  getEducation(): Observable<any> {
    return this.http.get(this.educationurl)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }

  addSkill(skill: ISkillModel) {
    return this.http.post(this.userSkillurl, skill)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }

  updateSkill(skill: ISkillModel, id: any): Observable<any> {
    return this.http.put(this.userSkillurl + '/' + id, skill)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }

  deleteMultipleSkills(deleteModel: DeleteModel) {
    return this.http.post(`${this.deleteSkillUrl}`, deleteModel);
  }

  addWorkPlatform(platform: IPlatform): Observable<any> {
    return this.http.post(this.workPlatformurl, platform)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }

  updateWorkPlatform(platform: IPlatform, id: number): Observable<any> {
    return this.http.put(this.workPlatformurl + '/' + id, platform)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }

  deleteMultipleWorkPlatform(deleteModel: DeleteModel) {

    return this.http.post(`${this.deleteWorkPlatformUrl}`, deleteModel);
  }
  deleteMultiple(deletedTransaction: IDeletedTransaction){
    return this.http.post(`${this.url}/many-delete-user`,deletedTransaction);
  }
  getUserPreferences(userId: any) {
    return this.http.get(this.userPreferenceUrl + `/GetPreferenceByUser?UserId=${userId}`)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }

  addUserPreferences(preferences:any) {
    return this.http.post(this.userPreferenceUrl+"/AddUpdateMultipleUserPreferences" , preferences)
    .pipe(map((val: any) => this.extractData(val)),
      catchError(err => this.handleError(err)));
  }
  updatePreferences(preference: any) {
    return this.http.put(this.userPreferenceUrl, preference)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }
  updateDetails(profileDetails: any) {
    return this.http.put(this.url + '/' + profileDetails.id, profileDetails)
      .pipe(map((val: any) => this.extractData(val)),
        catchError(err => this.handleError(err)));
  }

  getUserNotification(userId: number) {
    return this.http.get(environment.API_URL + `/NotifyHub/GetNotificationForHeader?UserId=${userId}`);
  }
}
