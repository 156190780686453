import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnsureLoadedOnceGuard } from '../ensure-loaded-once.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ComingsoonComponent } from './pages/comingsoon/comingsoon.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Error500Component } from './pages/error500/error500.component';
import { Error503Component } from './pages/error503/error503.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HelpdeskComponent } from './pages/helpdesk/helpdesk.component';
import { MaintenenceComponent } from './pages/maintenence/maintenence.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NearMeComponent } from './pages/near-me/near-me.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermConditionComponent } from './pages/term-condition/term-condition.component';
import { UserFeedbackComponent } from './pages/user-feedback/user-feedback.component';

@NgModule({
  declarations: [NotFoundComponent, AboutUsComponent, ComingsoonComponent, Error500Component, Error503Component, FaqComponent, HelpdeskComponent, MaintenenceComponent, NearMeComponent, PrivacyComponent, TermConditionComponent, UserFeedbackComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
  ]
})
export class CoreModule extends EnsureLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}


