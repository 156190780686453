import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/services/toast.service';
import { ITask } from 'src/app/features/tasks/models/task.model';
import { TaskService } from 'src/app/features/tasks/services/task.service';

@Component({
  selector: 'task-track-modal',
  templateUrl: './task-track-modal.component.html',
  styleUrls: ['./task-track-modal.component.scss']
})
export class TaskTrackModalComponent implements OnInit, OnChanges {

  modalRef!: NgbModalRef;

  @Input() isActive!: boolean;
  @Input() task!: ITask;
  @Output() onClosedModal: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('content') content!: ElementRef;

  constructor(
    private modalService: NgbModal,
    private toastSvc: ToastService,
    private taskSvc: TaskService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive && changes.isActive.currentValue) {
      this.modalRef = this.modalService.open(this.content, {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'lg'
      });
      this._getTaskTracking();
    } else {
      if (this.modalRef) {
        this.modalRef.close();
        this.isActive = false;
      }
    }
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
      this.isActive = false;
      this.onClosedModal.emit(false);

    }
  }

  private _getTaskTracking() {
    this.taskSvc.getTracking(this.task?.taskId).subscribe(console.log)
  }

}
