import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IMenu } from '../../models/menu.model';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnChanges {

  menus: IMenu[] = <IMenu[]>environment.USER_MENU.filter(x => x.isDisplay);

  @Input() isAuthenticated: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() currentRoute: string = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isAdmin)
      this.menus = changes.isAdmin.currentValue ? <IMenu[]>environment.ADMIN_MENU.filter(x => x.isDisplay) : <IMenu[]>environment.USER_MENU.filter(x => x.isDisplay);
  }

  ngOnInit(): void {
  }

}
