import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAccordion]',
  host: { 'href': 'javascript:void(0)' }

})
export class AccordionDirective {

  @Input() targetId!: string;
  @Input() classToAdd!: string;
  @Input() commonClass!: string;
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click') onClick() {

    let target = document.getElementById(this.targetId);
    if (!target)
      return;

    let accordionParents = document.getElementsByClassName('dropdown-toggle');
    let parentMenu = <HTMLElement>this.el.nativeElement;

    let hasCollapsed = parentMenu.classList.contains('collapsed');
    for (let index = 0; index < accordionParents.length; index++) {
      if (accordionParents[index].getAttribute('data-toggle') === 'collapse') {
        accordionParents[index].setAttribute('data-active', `false`);
        accordionParents[index].setAttribute('aria-expanded', `false`);
        this.renderer.removeClass(accordionParents[index], 'collapsed');

      }
    }


    parentMenu.setAttribute('aria-expanded', `${(!hasCollapsed)}`);
    parentMenu.setAttribute('data-active', `${(!hasCollapsed)}`);
    if (!hasCollapsed)
      this.renderer.addClass(parentMenu, 'collapsed');
    else
      this.renderer.removeClass(parentMenu, 'collapsed');

    let hasClass = target.classList.contains(this.classToAdd);

    let subMenus = document.getElementsByClassName(this.commonClass);
    for (let index = 0; index < subMenus.length; index++) {
      this.renderer.removeClass(subMenus[index], this.classToAdd);
    }


    if (!hasClass)
      this.renderer.addClass(target, this.classToAdd);
    else
      this.renderer.removeClass(target, this.classToAdd);
  }

}
