import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './core/pages/not-found/not-found.component';
import { LayoutComponent } from './layout/layout.component';
import {AdminPushNotificationComponent} from "./features/admin-push-notification/admin-push-notification.component";

const routes: Routes = [
  { path: '', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule) },
  { path: 'login', loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule) },
  { path: 'team', loadChildren: () => import('./features/team/team.module').then(m => m.TeamModule) },
  {
    path: '', component: LayoutComponent, children: [

      { path: 'my-tasks', loadChildren: () => import('./features/my-tasks/my-tasks.module').then(m => m.MyTasksModule) },
      { path: 'tasks', loadChildren: () => import('./features/tasks/tasks.module').then(m => m.TasksModule) },
      { path: 'task-details/:id', loadChildren: () => import('./features/task-details/task-details.module').then(m => m.TaskDetailsModule) },

      { path: 'dashboard', loadChildren: ()=> import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)},
      { path: 'payment-types', loadChildren: () => import('./features/payment-type/payment-type.module').then(m => m.PaymentTypeModule) },
      { path: 'privacy-type', loadChildren: () => import('./features/privacy-type/privacy-type.module').then(m => m.PrivacyTypeModule) },
      { path: 'task-categories', loadChildren: () => import('./features/task-categories/task-categories.module').then(m => m.TaskCategoriesModule) },
      { path: 'task-priorities', loadChildren: () => import('./features/task-priorities/task-priorities.module').then(m => m.TaskPrioritiesModule) },
      { path: 'task-status', loadChildren: () => import('./features/task-status/task-status.module').then(m => m.TaskStatusModule) },
      { path: 'user-notification', loadChildren: () => import('./features/user-notification/user-notification.module').then(m => m.UserNotificationModule) },
      { path: 'user-photo', loadChildren: () => import('./features/user-photo/user-photo.module').then(m => m.UserPhotoModule) },
      { path: 'profile', loadChildren: () => import('./features/user/profile/profile.module').then(m => m.ProfileModule) },
      { path: 'account-setting', loadChildren: () => import('./features/user/account-setting/account-setting.module').then(m => m.AccountSettingModule) },
      { path: 'transaction-types', loadChildren: () => import('./features/transaction-type/transaction-type.module').then(m => m.TransactionTypeModule) },
      
      { path: 'admin-invoice', loadChildren: () => import('./features/admin/admin-invoice/admin-invoice.module').then(m => m.AdminInvoiceModule) },
      { path: 'admin-notification', loadChildren: () => import('./features/admin/admin-notification/admin-notification.module').then(m => m.AdminNotificationModule) },
      { path: 'admin-feedback', loadChildren: () => import('./features/admin-feedback/admin-feedback.module').then(m => m.AdminFeedbackModule) },
      { path: 'promo-code', loadChildren: () => import('./features/promo-code/promo-code.module').then(m => m.PromoCodeModule) },
      { path: 'roles', loadChildren: () => import('./features/roles/roles.module').then(m => m.RolesModule) },
      { path: 'notifications', loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationModule) },
      { path: 'notify', loadChildren: () => import('./features/notify-message/notifymessage.module').then(m => m.NotifymessageModule) },
      { path: 'admin-contact-us', loadChildren: () => import('./features/contact-us/contact-us.module').then(m => m.ContactUsModule) },
      { path: 'contacts', loadChildren: () => import('./features/contacts/contacts.module').then(m => m.ContactsModule) },
      { path: 'spog', loadChildren: () => import('./features/spog/spog.module').then(m => m.SpogModule) },
      { path: 'task-proposals', loadChildren: () => import('./features/task-proposals/task-proposals.module').then(m => m.TaskProposalsModule) },
      { path: 'task-progress/:id', loadChildren: () => import('./features/task-progress/task-progress.module').then(m => m.TaskProgressModule) },
      { path: 'payment', loadChildren: () => import('./features/payment/payment.module').then(m => m.PaymentModule) },
      { path: 'invoice', loadChildren: () => import('./features/invoice/invoice.module').then(m => m.InvoiceModule) },
      { path: 'notification-type', loadChildren: () => import('./features/notification-type/notification-type.module').then(m => m.NotificationTypeModule) },

      { path: 'chat', loadChildren: () => import('./features/chat/chat.module').then(m => m.ChatModule) },
      { path: 'push' , component: AdminPushNotificationComponent},
      { path: 'users', loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule) },
      { path: 'user-role', loadChildren: () => import('./features/user-role/user-role.module').then(m => m.UserRoleModule) },
      { path: 'groups', loadChildren: () => import('./features/groups/groups.module').then(m => m.GroupsModule) },


    ]
  },
  { path: 'user-feedback', loadChildren: () => import('../app/core/pages/user-feedback/user-feedback.module').then(m => m.UserFeedbackModule) },
  { path: 'contact-us', loadChildren: () => import('../app/core/pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'term-condition', loadChildren: () => import('../app/core/pages/term-condition/term-condition.module').then(m => m.TermConditionModule) },
  { path: 'privacy', loadChildren: () => import('../app/core/pages/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'near-me', loadChildren: () => import('../app/core/pages/near-me/near-me.module').then(m => m.NearMeModule) },
  { path: 'maintenence', loadChildren: () => import('../app/core/pages/maintenence/maintenence.module').then(m => m.MaintenenceModule) },
  { path: 'helpdesk', loadChildren: () => import('../app/core/pages/helpdesk/helpdesk.module').then(m => m.HelpdeskModule) },
  { path: 'faq', loadChildren: () => import('../app/core/pages/faq/faq.module').then(m => m.FaqModule) },
  { path: 'error503', loadChildren: () => import('../app/core/pages/error503/error503.module').then(m => m.Error503Module) },
  { path: 'error500', loadChildren: () => import('../app/core/pages/error500/error500.module').then(m => m.Error500Module) },
  { path: 'coming-soon', loadChildren: () => import('../app/core/pages/comingsoon/comingsoon.module').then(m => m.ComingsoonModule) },
  { path: 'about-us', loadChildren: () => import('../app/core/pages/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: '**', component: NotFoundComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
