<!--  BEGIN NAVBAR  -->
<div class="sub-header-container">
    <header class="header navbar navbar-expand-sm py-0">
        <a (click)="toggleSideNav()" class="sidebarCollapse">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-menu">
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
        </a>

        <ul class="navbar-nav flex-row">
            <li>
                <div class="page-header">

                    <div class="breadcrumb-four">
                        <ul class="breadcrumb">
                            <li tooltip="{{ 'Click to go on home' | languageTranslator | async}}">
                                <a routerLink="/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-home">
                                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                    </svg>
                                </a>
                            </li>

                          <li class="active">
                            <a href="javascript:void(0);">
                              <i placement="bottom" [show-delay]="500"
                                 tooltip="{{ 'Click to go on '+title | languageTranslator | async}}"
                                 class="far fa-paper-plane" style="font-size: 20px;"></i>
                              <span> {{ "Your are on - "+title | languageTranslator | async}}</span></a>
                          </li>

                          <li *ngIf="isAuthenticated">
                                <i   (click)="openPreferecesModel()" class="fas fa-user-cog" style="cursor: pointer;"></i>
                            </li>



                        </ul>

                    </div>



                </div>
            </li>
        </ul>
      <div class="pup-container" *ngIf="messages">
        <ngb-carousel>
          <ng-container *ngFor="let message of messages">
            <ng-template ngbSlide>
              <a href="javascript:void(0);">
                <span>
                  <b class="title">{{ message | languageTranslator | async}} </b>
                </span>
              </a>
            </ng-template>
          </ng-container>
        </ngb-carousel>

      </div>
    </header>
</div>
<!--  END NAVBAR  -->
<ng-template #addPreferences let-modal>
    <div  id="addPreferencesModel" role="dialog" aria-labelledby="addPreferencesModel" aria-hidden="true">

    <div class="modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addPreferencesModelTitle">
                    Add Preferences</h5>
                <button type="button" (click)="onCancelDiscard()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="row">
                    <div class="col-sm-12 padding">
                        <label
                            for="categories">{{ 'Select your preferences from here ' | languageTranslator | async}}</label>
                        <ng-select placement="right" show-delay:500
                            tooltip="{{ 'Choose desire category ' | languageTranslator | async}}"
                            [items]="autoCompleteList" [multiple]="true" [addTag]="true"
                            [(ngModel)]="selectedPreferences" bindLabel="text" bindValue="id" (valueChanged)="onPreferenceValueValueChanged($event)">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn" (click)="onCancelDiscard()">Close</button>
                <button class="btn btn-primary" (click)="submitPreferences()" [disabled]="isProcessing">Submit</button>
                <button class="btn btn-primary" [disabled]="selectedPreferences.length == 0"
                    (click)="clearPreference()">Reset</button>
            </div>
        </div>

    </div>
</div>
</ng-template>
