import { Component, OnInit } from '@angular/core';
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
  }

}
