import { Component, OnInit } from '@angular/core';
import { CanonicalService } from '../../services/canonical.service';
import { IAboutUsModel } from './models/AboutUsModel';
import { AboutUsService } from './services/about-us.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})


export class AboutUsComponent implements OnInit {
  privacies: IAboutUsModel[] = [];

  constructor(private privacyService: AboutUsService,private canonicalService: CanonicalService) { }

  ngOnInit() {
    this.canonicalService.setCanonicalURL();
    
    this.privacyService.getAll().subscribe((response) => {
      this.privacies = response;
    }, (error) => {
      console.error(error);
    })
  }
}