<div class="fixed-action-btn">
    <a class="btn-floating btn-large red" (click)="toggleOptions()">
        <i class="fa fa-2x fa-ellipsis-v"></i>
    </a>
    <ul [class.showIcons]="isActive">
        <li tooltip="{{ 'Chat' | languageTranslator | async}}" (click)="redirectToChat()" placement="left" [show-delay]="500">
            <a class="btn-floating red"> <i class="fa fa-comments"></i></a>
        </li>
        <li tooltip="{{ 'Quick Task Creation' | languageTranslator | async}}" placement="left" [show-delay]="500">
            <a class="btn-floating yellow darken-1"> <i class="fa  fa-plus"></i></a>
        </li>
        <li tooltip="{{ 'Notification' | languageTranslator | async}}" placement="left" [show-delay]="500" (click)="openQuickNotificationModal()">
            <a class="btn-floating green"> <i class="fa fa-envelope"></i></a>
        </li>
        <li (click)="refresh()">
            <a class="btn-floating blue"> <i class="fa fa-redo"></i></a>
        </li>
    </ul>
</div>

<ng-template let-modal #addQuickNotificationModal>
  <div class="modal-content">

    <div class="modal-body">
      <div class="progress">
        <div class="progress-bar" role="progressbar" [ngStyle]="{'width': currentWidth + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{currentWidth}}%</div>
      </div>
      <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
      <div class="add-contact-box">
        <div class="add-contact-content">
          <div class="row" *ngIf="currentWidth === 25">
            <div class="col-md-12 text-left">
              <div class="contact-name">
                <label for="c-task">{{ 'Subscribe Type' | languageTranslator | async}}</label>
                <ng-select  [(ngModel)]="subscribeType" [items]="subscribeTypes"  [placeholder]="'Please select a Subscribe Type...'"  bindLabel="value">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="currentWidth === 50">
              <div class="col-md-12 text-left">
                <div class="contact-name">
                  <label for="c-task">{{ 'Select Notification Type' | languageTranslator | async}}</label>
                  <ng-select  [(ngModel)]="notificationType" [items]="notificationTypes"  [placeholder]="'Please select a task...'"  bindLabel="value" bindValue="id">
                  </ng-select>
                 </div>
              </div>
            </div>
          <div class="row" *ngIf="currentWidth === 75">
            <div class="col-md-12 text-left">
              <div class="contact-name">
                <label for="c-task">{{ 'Select Task' | languageTranslator | async}}</label>
                <ng-select  [(ngModel)]="taskId" [items]="tasks"  [placeholder]="'Please select a task...'"  bindLabel="taskTitle" bindValue="taskId">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="currentWidth === 100">
            <div class="col-md-12 text-center">
              <div class="contact-name">
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn" data-dismiss="modal" (click)="closeModal()">{{ 'Cancel' | languageTranslator | async}}</button>

      <button  class="btn m-1 btn-primary" (click)="checkValidation()">{{ 'Next' | languageTranslator | async}} </button>
    </div>
  </div>
</ng-template>
