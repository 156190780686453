<div class="fixed-top">
  <ngx-ui-loader [fgsSize]="70" [logoUrl]="'/assets/img/logo/icon.png'"
  [logoSize]="75" [bgsSize]="64" [fgsType]="'folding-cube'"></ngx-ui-loader>
  <top-nav [isAuthenticated]="isAuthenticated" [currentUser]="currentUser" [userNotification]="userNotification"
        (onSignOut)="signOut()" (onLogin)="onShowLoginModal()" (onClickedUserNotification)="getUserNotification()">
    </top-nav>
    <sub-nav [isAuthenticated]="isAuthenticated" [title]="currentPageTitle" (onToggleSideMenu)="toggleSideMenu()">
    </sub-nav>
</div>
<div class="main-container" [class.sidebar-closed]="!isActive" [class.sbar-open]="isActive" id="container">
    <side-nav [isAuthenticated]="isAuthenticated" [isAdmin]="isAdmin" [currentRoute]="currentRoute"></side-nav>
    <div id="content" class="main-content p-3" #layoutContainer>


        <div class="overlay" [class.show]="isActive" (click)="removeOverlay()"></div>
        <router-outlet></router-outlet>
        <div class="w-100 layout-spacing" style="height: 1em;"></div>
        <app-footer></app-footer>
      <ng-template #content let-modal>
        <login-modal  (onCloseModal)="showLoginModal = false" (onSuccessLogin)="onLogin()">
        </login-modal>
      </ng-template>
        <page-selection-modal [showModal]="showPageSelectionModal" (onCloseModal)="showPageSelectionModal = false">
        </page-selection-modal>

    </div>
</div>
<fab *ngIf="isAuthenticated"></fab>

<chats *ngIf="isAuthenticated && !isChatComponent"></chats>
