import {
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LocalNotifications } from '@capacitor/local-notifications';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ICommonUser } from '../core/models/common-user.model';
import { AuthStorageService } from '../core/services/auth-storage.service';
import { SeoService } from '../core/services/seo.service';
import { ToastService, TOAST_TYPE } from '../core/services/toast.service';
import { IUserNotificationResponse } from '../features/user-notification/models/user-notification-reponse.model';
import { UserNotificationService } from '../features/user-notification/services/user-notification.service';
import { EventService } from '../shared/services/event.service';
import {Plugins} from "@capacitor/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
const {} = Plugins;
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class LayoutComponent implements OnInit, OnDestroy {


  @ViewChild('content') content!: ElementRef;
  @ViewChild('layoutContainer') layoutContainer!: ElementRef;
  isActive: boolean = true;
  toggleOverlay: boolean = false;

  isAuthenticated: boolean = false;
  isAdmin: boolean = false;
  currentRoute: string = '';

  currentPageTitle: string = '';
  currentUser!: ICommonUser;

  showLoginModal: boolean = false;

  subscriptions: Subscription[] = [];

  userNotification!: IUserNotificationResponse;
  showPageSelectionModal: boolean = false;
  isChatComponent: boolean;
  constructor(private router: Router, private authStorageSvc: AuthStorageService,
              private route: ActivatedRoute, private seoSvc: SeoService,
              private ngxLoader: NgxUiLoaderService,
              private toastrSvc: ToastService,
              private eventSvc: EventService,
              private userNotificationSvc: UserNotificationService,
              private ngZone: NgZone,
              private modalService: NgbModal

  ) {
    this.subscriptions.push(
      this.router.events.subscribe(evt => {
        this.ngxLoader.start();
        if (evt instanceof NavigationStart) {
          this.ngxLoader.start();
        }

        if (evt instanceof NavigationEnd) {

          this.currentRoute = evt.url;
          this._setSideMenuDisplay();
          this._init();
          this.ngxLoader.stop();
          this.isChatComponent = this.currentRoute === '/chat';
          if(this.layoutContainer) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }
      })
    );

    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return '';
            }
          }
          return '';
        })
      ).subscribe((title: string) => {
        this.currentPageTitle = title;
        this.seoSvc.setTitle(title);
        //add meta tags
      })
    );

    this.subscriptions.push(
      this.eventSvc.onLoginEvent.subscribe(res => {
        if (res) this.onShowLoginModal();
      })
    );

  }

  ngOnInit(): void {
    this._setSideMenuDisplay();
    this._init();
    this.getUserNotification();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isActive = event.target.innerWidth > 640;
  }

  toggleSideMenu() {
    this.isActive = !this.isActive;
  }

  removeOverlay() {
    this.isActive = false;
  }

  onShowLoginModal() {
    this.modalService.open(this.content, {
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
  }
  onLogin() {
    this.toastrSvc.show(TOAST_TYPE.SUCCESS, 'Success', 'Login successfully');
    this.showPageSelectionModal = true;
    window.location.reload();
  }

  signOut() {
    this.authStorageSvc.purge();
    this.toastrSvc.show(TOAST_TYPE.SUCCESS, 'Success', 'Logout successfully');
    window.location.reload();
    window.location.replace('/');
    this.ngxLoader.stop();
  }

  getUserNotification() {
    this.initHandleNotifications();
    this.subscriptions.push(
      this.userNotificationSvc.getNotificationsByUser(this.currentUser?.userId, 5).subscribe(data => {
        this.userNotification = data;
        const notifications = data.notificationsDetails.map(res => {
          return {id: res.notificationId, title: res.notificationSubject, body: res.notificationBody , smallIcon : "ic_launcher", largeIcon : "ic_launcher" , iconColor: "#0000FF", actionTypeId: 'redirect', extra:{
              data : res
            } };
        });
        LocalNotifications.schedule({
          notifications: notifications
        });

      })

    );
  }

  private _init() {
    this.isAuthenticated = this.authStorageSvc.isAuthenticated();
    this.isAdmin = this.authStorageSvc.isAdmin();
    this.currentUser = this.authStorageSvc.getCommonUserInfo();
  }

  private _setSideMenuDisplay() {
    this.isActive = window.innerWidth > 640;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  private initHandleNotifications() {

    LocalNotifications.addListener('localNotificationActionPerformed', notification => {
      if(notification.actionId === 'tap'){
        this.ngZone.run(() => {
          this.router.navigate([notification.notification.extra.url]);
        });
      }
    });
  }
}
