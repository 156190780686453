<div class="fq-header-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 mr-auto mt-5 text-md-left text-center">
                <a href="/home" class="ml-md-5">
                    <img alt="image-500" style="width: 62px;height:62px"
                        src="/assets/img/logo.png" class="theme-logo">
                </a>

                <a href="/" class="back-button" style="transform: translate(130%,-8%);"><i
                        class="fa fa-chevron-left"></i></a>


            </div>
        </div>
    </div>
</div>


<div class="helpdesk container">
    <div class="helpdeskWrapper">
        <div class="">
            <div class="row">
                <!--<div class="col-md-12 col-sm-12 col-12 text-center-1 mb-5">
          <a href="/my-tasks" class="">
            <img alt="image-500" style="width: 62px;height: 62px;" src="/assets/img/logo.png" class="theme-logo">
          </a>
  
          <a href="/" class="back-button"><i class="fa fa-chevron-left"></i></a>
  
  
        </div>-->
                <div class="col-md-12 col-sm-12 col-12 text-center mb-3">
                    <a href="#">
                        <h2 class="main-heading" style="margin-left: 0px;"><b>Helpdesk</b></h2>
                    </a>
                    <!--<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
           <span class="navbar-toggler-icon"></span>
          </button>
  
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item active">
                <a class="nav-link" href="pages_contact_us.html">Contact Us</a>
              </li>
            </ul>
          </div>-->
                </div>
            </div>
        </div>
    </div>
    <div class="helpdesk layout-spacing">

        <div class="hd-header-wrapper">
            <div class="row">
                <div class="col-md-12 text-center">
                    <!-- <h4 class="">CORK</h4>
              <p class="">A knowledge base Admin Template </p>-->

                    <div class="row">
                        <div class="col-xl-6 col-lg-7 col-md-7 col-sm-11 col-11 mx-auto">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-search">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                        </svg></span>
                                </div>
                                <input type="text" class="form-control"
                                    placeholder="Let's find your question in fast way" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hd-tab-section">
            <div class="row">
                <div class="col-md-12 mb-5 mt-5">

                    <div  class="accordion" id="hd-statistics">
                        <div class="card" *ngFor="let helpdesk of helpdesks; let i = index;">
                            <div class="card-header" id="hd-statistics-1">
                                <div class="mb-0">
                                    <div class="" data-toggle="collapse" role="navigation"
                                        [attr.data-target]="'#collapse-hd-statistics-' + i" aria-expanded="false"
                                        aria-controls="collapse-hd-statistics-1"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-help-circle">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                            <line x1="12" y1="17" x2="12" y2="17"></line>
                                        </svg>
                                        {{helpdesk.ques}}
                                    </div>
                                </div>
                            </div>

                            <div [attr.id]="'collapse-hd-statistics-' + i" class="collapse"
                                aria-labelledby="hd-statistics-1" data-parent="#hd-statistics">
                                <div class="card-body">
                                    <p>{{helpdesk.ans}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="hd-contact-section">
            <div class="hd-slider">
                <div class="row">
                    <div class="col-sm-12">
                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item active community-help">
                                    <div class="carousel-item-content">
                                        <h4 class="hd-slide-header">Get help by community.</h4>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-edit">
                                                        <path
                                                            d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                                                        </path>
                                                        <path
                                                            d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                                                        </path>
                                                    </svg>
                                                    <div class="media-body">
                                                        <h5>CORK Forum</h5>
                                                        <p class="">Lorem ipsum dolor sit amet, consectetur adipisicing
                                                            elit, sed do eiusmod
                                                            tempor.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-terminal">
                                                        <polyline points="4 17 10 11 4 5"></polyline>
                                                        <line x1="12" y1="19" x2="20" y2="19"></line>
                                                    </svg>
                                                    <div class="media-body">
                                                        <h5>How to Code</h5>
                                                        <p class="">Lorem ipsum dolor sit amet, consectetur adipisicing
                                                            elit, sed do eiusmod
                                                            tempor.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item news-updates ">
                                    <div class="carousel-item-content">
                                        <h4 class="hd-slide-header">Latest news and updates</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-bookmark">
                                                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z">
                                                        </path>
                                                    </svg>
                                                    <div class="media-body">
                                                        <h5>CORK Blog</h5>
                                                        <p class="">Lorem ipsum dolor sit amet, consectetur adipisicing
                                                            elit, sed do eiusmod
                                                            tempor incididunt ut labore et dolore magna aliqua.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-chevron-left">
                                        <polyline points="15 18 9 12 15 6"></polyline>
                                    </svg></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-chevron-right">
                                        <polyline points="9 18 15 12 9 6"></polyline>
                                    </svg></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<!--<div id="miniFooterWrapper" class="">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="position-relative">
              <div class="arrow text-center">
                <p class="">Up</p>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-5 mx-auto col-lg-6 col-md-6 site-content-inner text-md-left text-center copyright align-self-center">
                <p class="mt-md-0 mt-4 mb-0">2020 &copy; <a target="_blank" href="https://buywork.in/">buyWork.in</a>.</p>
              </div>
              <div
                class="col-xl-5 mx-auto col-lg-6 col-md-6 site-content-inner text-md-right text-center align-self-center">
                <p class="mb-0">buyWork.in G40 DELHI, NEW DELHI, INDIA 110001</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->

<footer class="index-footer">
    <div class="container text-center">
        <div class="links">
            <a href="/about-us">About Us</a>
            <a href="/privacy">Privacy Policy</a>
            <a href="/team">Team</a>
            <a href="/user-feedback">Share Feedback</a>
            <a href="/helpdesk">Self Helpdesk</a>
            <a href="/faq">FAQ</a>
            <a href="/contact-us">Contact Us</a>
            <a href="/term-condition"> Terms and Conditions</a>
            <a href="/near-me">Near Me </a>
        </div>
        <div class="social-links"> <a href="#"><i class="fab fa-twitter"></i></a> <a href="#"><i
                    class="fab fa-facebook"></i></a> <a href="#"><i class="fab fa-youtube"></i></a> <a href="#"><i
                    class="fab fa-linkendin"></i></a> </div>
        <span class="copyrights">© 2020 BUYWORK ALL RIGHTS RESERVED.</span>
        <div style="margin: 10px 0;">
            <img src="assets/img/visa.jpg" class="footer-img">
            <img src="assets/img/Mastercard_logo.jpg" class="footer-img">
            <img src="assets/img/maestro.jpg" class="footer-img">
            <img src="assets/img/Rupay.png" class="footer-img">
            <img src="assets/img/Net banking.jpg" class="footer-img">
            <img src="assets/img/Paytm-Logo.jpg" class="footer-img">
            <img src="assets/img/upi.png" class="footer-img">
            <img src="assets/img/discover.png" class="footer-img">
            <img src="assets/img/100-secure.jpg" class="footer-img">
        </div>
    </div>
</footer>