import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as atlas from 'azure-maps-control';
import { IMapEvent, RouteService, IMarkerEvent, SearchService } from 'ng-azure-maps';
import { LocationModel } from 'src/app/core/models/location.model';
import {LocationService} from "../../../core/services/location.service";
import * as rest from 'azure-maps-rest';
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

@Component({
  selector: 'app-azure-map',
  templateUrl: './azure-map.component.html',
  styleUrls: ['./azure-map.component.scss']
})
export class AzureMapComponent implements OnInit, OnChanges {

  route = new atlas.source.DataSource('route');
  marker: Observable<atlas.HtmlMarker[]>;
  mapEvent: IMapEvent;
  points: [number, number][] = [];
  strokeWidth: number = 4;
  center: [number, number] = [11.581990, 48.143534];
  trafficOptions: atlas.TrafficOptions = {
    flow: "absolute",
    incidents: false
  };

  @Input() from!: LocationModel;
  @Input() destination!: LocationModel;
  @Input() currentLocation!: LocationModel;
  searchAddress: any;
  address: any;

  constructor(
    private locationSvc: LocationService,
    private readonly routeService: RouteService,
    private readonly searchService: SearchService
  ) { }


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.from && changes.from.currentValue)
      // this.points[0] = [ this.from.longitude, this.from.latitude];
      this.points[0] = [ 77.223496, 28.604728]; //For testing as my location is too far
    if (changes.destination && changes.destination.currentValue)
      this.points[1] = [this.destination.longitude, this.destination.latitude];
    if (this.from && this.destination) {
      this.center = [this.from.longitude, this.from.latitude];
      this.setRoute();
    }
    if (this.currentLocation) {
      this.center = [this.currentLocation.longitude, this.currentLocation.latitude]

    }
  }

  getCurrentLocation() {
    this.locationSvc.getPosition().then(coordinates => {
      this.patchCoordinates(coordinates);
    }).catch(err => {
      this.patchCoordinates(environment.defaultLocation);
    });
  }

  private patchCoordinates(coordinates: any) {
    this.currentLocation = {
      latitude: coordinates.latitude,
      longitude: coordinates.longitude
    };
  }


  mapReady(mapEvent: IMapEvent) {
    if(this.currentLocation){
        this.getCurrentLocation();
    }else{
        this.mapEvent = mapEvent;
        this.route.clear();
    }


    // marker = new atlas.HtmlMarker({
    //   htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="37" viewBox="0 0 30 37" xml:space="preserve"><rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/><polygon fill="{color}" points="10,29 20,29 15,37 10,29"/><text x="15" y="20" style="font-size:16px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>',
    //   color: 'Purple',
    //   text: '2',
    //   position: [0, 0]
    // });

    //Create a HTML marker and add it to the map.
    //map.markers.add(marker);
  }

  setRoute() {
    if (this.points.length === 2) {
      this.routeService.calculateRouteDirections(this.points, {
        routeType: rest.Models.RouteType.Shortest,
        sectionType: rest.Models.SectionType.TravelMode,
        travelMode: rest.Models.TravelMode.Car,
        maxAlternatives: 1
      }).then(result => {
        const features = result.geojson.getFeatures();
        this.route.add(features.features);
        this.mapEvent.map.setCamera({
          bounds: features.bbox,
          padding: 35,
        });
      });
    }

  }

}
