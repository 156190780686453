import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CanonicalService } from '../../services/canonical.service';
import { ComingsoonService } from '../../services/comingsoon.service';
import { IContactUsModel } from '../models/contact-us.model';


@Component({
  selector: 'app-comingsoon',
  templateUrl: './comingsoon.component.html',
  styleUrls: ['./comingsoon.component.scss']
})
export class ComingsoonComponent implements OnInit, OnDestroy {
  contactUsModel: IContactUsModel = { name: '', email: '', message: '', method: 0, phone: '' };
  countdownfunction: any;

  constructor(private comingsoonService: ComingsoonService,
    private canonicalService: CanonicalService) { }

  ngOnInit() {
    this.canonicalService.setCanonicalURL();
    this.countdownfunction = setInterval(() => {

      // Get todays date and time
      let now = new Date().getTime();

      // Find the distance between now an the count down date
      let distance = environment.LaunchEpoc - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      document.getElementById("days").innerText = days + "";
      document.getElementById("hours").innerText = hours + "";
      document.getElementById("mins").innerText = minutes + "";
      document.getElementById("secs").innerText = seconds + "";

      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(this.countdownfunction);
        document.getElementById("timer").innerHTML = "EXPIRED";
      }
    }, 1000);

  }

  subscribe() {
    this.comingsoonService.add(this.contactUsModel).subscribe();
  }

  ngOnDestroy(): void {
    clearInterval(this.countdownfunction);
  }

}
