import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'page-selection-modal',
  templateUrl: './page-selection-modal.component.html',
  styleUrls: ['./page-selection-modal.component.scss']
})
export class PageSelectionModalComponent implements OnInit, OnChanges {

  @Input() showModal: boolean = false;
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter();

  @ViewChild('content') content!: ElementRef;
  timer: number;

  constructor(private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue)
      this.init();
  }

  init() {
    this.modalService.open(this.content, {
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    this.startTimer();
  }
  closeModal() {
    this.showModal = false;
    this.onCloseModal.emit();
    this.modalService.dismissAll();
  }

  goToTasksPage() {
    this.router.navigateByUrl('/my-tasks?in=home');
    this.closeModal();
  }
  goToCreateTask() {
    this.router.navigateByUrl('/tasks?showCreateTaskModal=true');
    this.closeModal();
  }

  private startTimer() {
    this.timer = 5;
    const intervalId = setInterval(() => {
      this.timer = this.timer - 1;
      if (this.timer === 0){
        clearInterval(intervalId);
        this.goToTasksPage();
      }
    }, 1000);
  }
}
