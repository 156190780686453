import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../../../../environments/environment";
import {ICommonUser} from "../../../core/models/common-user.model";
import {AuthStorageService} from "../../../core/services/auth-storage.service";
import {TaskService} from "../../../features/tasks/services/task.service";
import {NotificationHubService} from "../../../core/services/notification-hub.service";

declare const Swal: any;
@Component({
  selector: 'fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss']
})
export class FabComponent implements OnInit {


  @ViewChild('addQuickNotificationModal') addQuickNotificationModal: ElementRef;
  isActive: boolean = false;
  notificationTypes: any [] = [];
  commonUser: ICommonUser;
  notificationType: any;
  currentWidth = 25;
  subscribeTypes = [  'Picker', 'Admin', 'Dropper'];
  subscribeType = 'Picker';
  taskId: any;
  tasks: any;

  constructor(private router: Router, private modal : NgbModal, private accountStorage: AuthStorageService, private taskService: TaskService,
              private notificationHubService: NotificationHubService,) { }

  ngOnInit(): void {
    this.commonUser = this.accountStorage.getCommonUserInfo();
    this.taskService.getAll().subscribe(res => {
      this.tasks = res;

    });
  }
  toggleOptions() {
    this.isActive = !this.isActive;
  }

  refresh() {
    window.location.reload();
  }

  redirectToChat() {
   this.router.navigate(['/chat'])
  }

  openQuickNotificationModal() {
    this.notificationTypes =  this.commonUser.isAdmin ? environment.adminNotificationType : environment.userNotificationType;
    this.notificationType = this.notificationTypes[0].id;
    this.modal.open(this.addQuickNotificationModal, {
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
  }

  checkValidation() {
    if ((this.currentWidth === 25 && this.subscribeType) ||
      (this.currentWidth === 50 && this.notificationType) ||
      (this.currentWidth === 75 && this.taskId)) {
      this.goToNextStep();
    }else if(this.currentWidth === 100) {
      this.submitNotification();
    }
  }


  goToNextStep() {
    this.currentWidth = this.currentWidth + 25;
  }
  private submitNotification() {
    this.notificationHubService.sendNotification(this.commonUser.userId.toString(), '0', this.taskId, this.taskId, this.subscribeType, this.notificationType)
  }

  closeModal() {
    this.currentWidth =  25;
    this.modal.dismissAll();
  }
}
