import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import {ChatService} from "../../../features/chat/services/chat.service";
import {Observable} from "rxjs";
import {IChat} from "../../../features/chat/models/chat.model";
import {ICommonUser} from "../../../core/models/common-user.model";
import {AuthStorageService} from "../../../core/services/auth-storage.service";
import {DatePipe} from "@angular/common";
import {IChatRoom} from "../../../features/chat/models/chat-room.model";
import {IOnlineUser} from "../../../features/chat/models/online-user.model";
import {map} from "rxjs/operators";

@Component({
  selector: 'chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {

  @ViewChild('chatcontent') private myScrollContainer: ElementRef;
  @ViewChild('secondChatContent') private secondChatContent: ElementRef;


  isActive: boolean = false;
  isOpenChat: boolean;
  chatRooms$: any;
  rooms: any;
  searchInput: string;
  userName: string;
  chats: any [] = [];
  currentRoom: any [] = [];
  chats$: Observable<IChat[]> [] = [];
  messages: string [] = [];
  isOpenFirstChat: boolean;
  commonUser: ICommonUser;
  message: string;
  isOpenSecondChat: boolean;
  currentLength: number = 0;
  maxLength: number;
  isGroup = true;
  onlineUsers$: Observable<IOnlineUser[]>;
  scrolltop = 0;
   onlineUser: any [] = [];
  constructor(private chatService: ChatService,private accountStorage: AuthStorageService,
              private datePipe: DatePipe,
              private ngZone: NgZone,
              private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.commonUser = this.accountStorage.getCommonUserInfo();
    this.userName = this.commonUser.userName;
    this.chatRooms$ = this.chatService.getChatRooms();
    this.chatRooms$.subscribe((res: any) => this.rooms = res);

    this.onlineUsers$ = this.chatService.getOnlineUsers();
    this.chatService.onlineUsersListener().subscribe(res => {
      this.onlineUsers$.pipe(map(data => {
        const index = data.findIndex(item => item.key === res.key);
        data[index] = res;
        return [...data];
      }));
    });
    this.chatService.addOnlineUser({ userName: this.userName, status: 'ONLINE' });
  }
  toggleOptions() {
    this.isActive = !this.isActive;
  }

  refresh() {
    window.location.reload();
  }


  openChat() {
    this.isOpenChat = !this.isOpenChat;
  }

  openFirstChat(firstChat: HTMLDivElement) {
    this.isOpenFirstChat = !this.isOpenFirstChat;
    try {
     this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }catch (e) {
      //console.log(e);
    }
    }
  async onClickRoom(room: IChatRoom) {
    if (!this.chats.find(chat => room.key === chat.key)){
      const currentRoom =  { ...room };
      await this.chats.push(currentRoom);
      this.currentRoom.push( currentRoom);
      this.ngZone.run(async () => {
        this.chats$.push();
        this.chats$ [this.currentLength] = await this.chatService.getChatsInChatRoom(currentRoom.roomName);
        this.currentLength = this.currentLength + 1;
        this.chatService.getChatsInChatRoom(currentRoom.roomName).subscribe(res => {
        })
      });
    }


   }

  async onClickUser(user: IOnlineUser) {
    if (!this.chats.find(chat => user.key === chat.key)) {
      this.isGroup = false;
      const roomName = this.userName < user.userName ? `${this.userName}_${user.userName}` : `${user.userName}_${this.userName}`;
      // @ts-ignore
      const currentRoom = {roomName: user.userName}
      const currentRoomWithKey = {roomName: user.userName, key: user.key}
      await this.chats.push(currentRoomWithKey);
      this.currentRoom.push(currentRoom);
      this.ngZone.run(async () => {
        this.chats$.push();
        this.chats$[this.currentLength] = this.chatService.getChatsInByUser(roomName);
        this.currentLength = this.currentLength + 1;
        this.chatService.getChatsInByUser(roomName).subscribe(res => {
          setTimeout(() => {
            this.scrollFirstChat(res);
            this.scrollSecondChat(res);
            this.chatService.resetSmsCounter(user.userName, this.userName);

          }, 400);
        });
      });
    }
  }

  onSendMessage(currentRoom: any, counter: number) {
    if (this.messages[counter] && this.messages[counter].length <= 250) {

      const chat = {
        date: this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        message: this.messages[counter],
        userName: this.userName,
        roomName: currentRoom.roomName,
        unreadMessage: 0
      };

      if (this.isGroup) {
        this.chatService.addChat(chat);

      } else {
        let roomName = this.userName < currentRoom.roomName ? `${this.userName}_${currentRoom.roomName}` : `${currentRoom.roomName}_${this.userName}`;
        chat.roomName = roomName;
        this.chatService.addChatByUser(chat, roomName, this.userName, currentRoom.roomName);

      }

      this.messages[counter] = '';
    } else {

    }
  }

  async closeChat(index: number) {
    this.chats.splice(this.currentLength - index, 1);
    this.currentRoom.splice(this.currentLength - index, 1);
    this.chats$.splice(this.currentLength - index, 1);
    this.currentLength = this.currentLength - 1;
  }

  openSecondChat() {
    this.isOpenSecondChat = !this.isOpenSecondChat;
    this.secondChatContent.nativeElement.scrollTop = this.secondChatContent.nativeElement.scrollHeight;
  }




  private scrollFirstChat(res:any) {
    const room = this.chats[this.currentRoom.length > 1 ? this.currentRoom.length - 2 : this.currentRoom.length - 1]
    const roomName = this.userName < room.roomName ? `${this.userName}_${room.roomName}` : `${room.roomName}_${this.userName}`;
    if(res && res[0] && roomName === res[0].roomName) {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }
  }

  private scrollSecondChat(res: IChat[]) {
    const roomName = this.userName < this.chats[this.currentLength - 1].roomName ? `${this.userName}_${this.chats[this.currentLength - 1].roomName}` : `${this.chats[this.currentLength - 1].roomName}_${this.userName}`
    if(res && res[0]  && roomName === res[0].roomName  && this.chats && this.chats.length > 1) {
      this.secondChatContent.nativeElement.scrollTop = this.secondChatContent.nativeElement.scrollHeight;
    }
  }
}
