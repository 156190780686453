<!-- Modal -->
<ng-template #content let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Upload Documents for Verifications
            </h5>
            <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body justify-content-center">
            <div class="row">
                <div class="col-md-12">
                    <h5>Terms Of Conditions</h5>
                    <p class="text-justify text-wrap">Terms of service are the legal agreements between a
                        service provider and a person who wants to use that service.
                        The person must agree to abide by the terms of service in
                        order to use the offered service. Terms of service can also be merely a disclaimer, especially
                        regarding the use of websites</p>
                    <div class="form-check form-group mt-2">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked>
                        <label class="form-check-label" for="flexCheckDefault">
                            I accept the Terms of Conditions
                        </label>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="verificationTypes">Document Type</label>
                        <ng-select [(ngModel)]="verificationType" name="verificationTypes"
                            [items]="verificationTypes$ | async" [multiple]="false" bindLabel="verificationType"
                            bindValue="id">
                        </ng-select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">

                    <div class="custom-file-container" data-upload-id="mySecondImage">
                        <label>Upload (Allow Multiple) <a class="custom-file-container__image-clear"
                                title="Clear recent selected image">x</a></label>
                        <label class="custom-file-container__custom-file">
                            <input type="file" #file class="custom-file-container__custom-file__custom-file-input"
                                (change)="onFileUpload($event)" multiple>
                            <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                            <span class="custom-file-container__custom-file__custom-file-control">Choose file...<span
                                    class="custom-file-container__custom-file__custom-file-control__button"> Browse
                                </span></span>
                        </label>
                        <div class="form-row">
                            <div class="col-md-4 col-sm-6" *ngFor="let item of userImages; let i=index">
                                <span style="margin-left: 18px" (click)="removeImage(i, item.id)"
                                    class="custom-file-container__image-multi-preview__single-image-clear">
                                    <span class="custom-file-container__image-multi-preview__single-image-clear__icon"
                                        data-upload-token="9b6418pa85g83kmwbqic68">×</span>
                                </span>
                                <img class="img-upload" [src]="item.photo" alt="">

                            </div>
                        </div>
                    </div>



                </div>
            </div>

        </div>

        <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" (click)="onSubmit()">
                {{ 'Submit' | languageTranslator | async}}</button>
        </div>

    </div>
</ng-template>

<ng-template #removeImageEl let-modal>
    <div class="modal-content">
        <div class="modal-body pt-4 pb-4">
            <h5 class="text-center">Are you sure you want to delete?</h5>
        </div>
        <div class="modal-footer justify-content-center">

            <button class="btn cancel" (click)="modal.dismiss('Cross click')">Cancel</button>

            <button class="btn btn-primary" (click)="onConfirmImageDelete()">Confirm</button>
        </div>
    </div>

</ng-template>