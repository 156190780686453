<div class="fixed-action-btn"  [ngClass]="isOpenChat ? 'open' : ''" >
  <div class="MessagingInboxWidget"(click)="openChat()">
    <h1><a>Messages</a></h1>
    <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!isOpenChat">
      <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
      <path d="M13 30L25 18L37 30" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg width="24" height="24" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" *ngIf="isOpenChat">
      <polyline fill="none"   stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" points="7.086 3.174 17.086 13.174 7.086 23.174" transform="scale(1 -1) rotate(-89 -1.32 0)"/>
    </svg>

  </div>
  <div class="search">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
         class="feather feather-search">
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </svg>
    <input type="text" class="form-control" [(ngModel)]="searchInput" name="searchInput" placeholder="Search">
  </div>

  <div class="people" *ngIf="isOpenChat">
    <ng-container *ngIf="isOpenChat && chatRooms$ | async as chatRooms">
      <div class="person" *ngFor="let room of chatRooms | filter:searchInput" (click)="onClickRoom(room)">
        <div class="user-info">
          <div class="f-head">
            <img src="/assets/img/90x90.jpg" alt="avatar">
          </div>
          <div class="f-body">
            <div class="meta-info">
              <span class="user-name">{{room.roomName}}</span>
              <!-- <span class="user-meta-time">2:09 PM</span> -->
            </div>
            <!-- <span class="preview">I was wondering...</span> -->
          </div>
        </div>
      </div>
    </ng-container>
    <h4 class="ml-3 online-header">Online</h4>

    <ng-container *ngIf="onlineUsers$ | async as onlineUsers">
      <div class="person" *ngFor="let user of onlineUsers | filter:searchInput" (click)="onClickUser(user)">
        <div class="user-info">
          <div class="f-head" style="position: relative;">
            <img src="/assets/img/90x90.jpg" alt="avatar">
            <div class="presence" [class.active]="user.status === 'ONLINE'"></div>

          </div>
          <div class="f-body">
            <div class="meta-info">
              <span class="user-name">{{user.userName}}</span>
               <span class="user-meta-time sms-counter" *ngIf="user.chats && user.chats[userName] && user.chats[userName].unread">{{user.chats[userName].counter}}</span>
            </div>
            <!-- <span class="preview">I was wondering...</span> -->
          </div>
        </div>
      </div>
    </ng-container>


  </div>


</div>

<div #firstChat *ngIf="chats && chats.length > 0">
  <div class="fixed-action-btn first-chat"  [ngClass]="isOpenFirstChat ? 'open' : ''" >
    <div class="chat-meta-user chat-active" >
      <div class="current-chat-user-name" (click)="openFirstChat(firstChat)"><span><img
        src="/assets/img/90x90.jpg" alt="dynamic-image"><span
        class="name">{{currentRoom[currentRoom.length > 1 ? currentRoom.length - 2 : currentRoom.length - 1 ]?.roomName}}</span></span>
      </div>
      <button type="button" class="close" data-dismiss="modal" (click)="closeChat(2)" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="chat-conversation-box"  #chatcontent  >
      <div class="chat-conversation-box-scroll" >
        <div class="chat active-chat">
          <ng-container *ngIf="chats$[currentRoom.length > 1 ? currentRoom.length - 2 : currentRoom.length - 1] | async as chats">
            <div *ngFor="let chat of chats">
              <div class="bubble" [ngClass]="(userName === chat.userName)?'me':'you'">
                <p *ngIf="userName !== chat.userName" class="mb-0"
                   style="font-weight: bold; font-size: 12px;">
                  {{chat.userName}}</p>
                {{chat.message}}
                <p class="mb-0" style="font-weight: bold; font-size: 12px; font-style: italic;">{{chat.date}}
                </p>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
    <div class="chat-footer chat-active">
      <div class="chat-input">
        <form class="chat-form" action="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-message-square">
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
          <input [disabled]="!currentRoom" type="text" class="mail-write-box form-control"
                 placeholder="Message" [(ngModel)]="messages[currentRoom.length > 1 ? currentRoom.length - 2 : currentRoom.length - 1]" name="message" (keydown.enter)="onSendMessage(currentRoom[currentRoom.length > 1 ? currentRoom.length - 2 : currentRoom.length - 1], currentRoom.length > 1 ? currentRoom.length - 2 : currentRoom.length - 1)">
        </form>
      </div>
    </div>

  </div>
</div>
<div #second_chat *ngIf="chats && chats.length > 1">
  <div class="fixed-action-btn second-chat"  [ngClass]="isOpenSecondChat ? 'open' : ''" >
    <div class="chat-meta-user chat-active" >
      <div class="current-chat-user-name" (click)="openSecondChat()"><span><img
        src="/assets/img/90x90.jpg" alt="dynamic-image"><span
        class="name">{{currentRoom[currentLength - 1]?.roomName}}</span></span>
      </div>
      <button type="button" class="close" data-dismiss="modal" (click)="closeChat(1)" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="chat-conversation-box" #secondChatContent>
      <div id="chat-conversation-box-scroll" class="chat-conversation-box-scroll">
        <div class="chat active-chat">

          <ng-container *ngIf="chats$[currentLength - 1] | async as chats">
            <div *ngFor="let chat of chats">

              <div class="bubble" [ngClass]="(userName === chat.userName)?'me':'you'">
                <p *ngIf="userName !== chat.userName" class="mb-0"
                   style="font-weight: bold; font-size: 12px;">
                  {{chat.userName}}</p>
                {{chat.message}}
                <p class="mb-0" style="font-weight: bold; font-size: 12px; font-style: italic;">{{chat.date}}
                </p>
              </div>
            </div>
          </ng-container>



        </div>
      </div>
    </div>
    <div class="chat-footer chat-active">
      <div class="chat-input">
        <form class="chat-form" action="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-message-square">
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
          <input [disabled]="!currentRoom" type="text" class="mail-write-box form-control"
                 placeholder="Message" [(ngModel)]="messages[currentLength - 1]" name="message" (keydown.enter)="onSendMessage(currentRoom[currentLength - 1], currentLength - 1)">
        </form>
      </div>
    </div>

  </div>
</div>
