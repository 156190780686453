import { Component, OnInit } from '@angular/core';
import { CanonicalService } from '../../services/canonical.service';
import { FeedbackService } from '../../services/feedback.service';
import { ToastService, TOAST_TYPE } from '../../services/toast.service';
import { IFeedbackModel } from '../models/feedback.model';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss']
})
export class UserFeedbackComponent implements OnInit {
  public id = 0;
  public email = '';
  public message = '';
  public phone = '';
  public name = '';
  public createdBy = 0;
  phoneValid = true;
  validateEmail = true;




  feedback: IFeedbackModel = {
    id: this.id, email: this.email, message: this.message,
    phone: this.phone, name: this.name, createdBy: 0, createdOn: new Date(), isActive: true
  };
  constructor(private feedbackService: FeedbackService, 
    private toastService: ToastService,
    private canonicalService: CanonicalService
    
    ) { }


  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
  }
  saveFeedback() {
    this.feedbackService.add(this.feedback).subscribe(() => {
      this.feedback = { id: 0, email: '', message: '', phone: '', name: '', createdBy: 0, createdOn: new Date(), isActive: true };
      this.toastService.show(TOAST_TYPE.SUCCESS, 'Success', 'User Feedback submitted successfully.');
    }, (error) => {
      this.toastService.show(TOAST_TYPE.ERROR, 'Error', 'User Feedback not submitted successfully.');
    });
  }

  Submit() {
    if (this.feedback.email.toString() != '' && this.feedback.phone != '') {
      // this.contactUsModel.createdBy = this.commonUser.userId;
      // this.contactUsModel.updatedBy = this.commonUser.userId;
      this.feedback.createdBy = 1;
      this.saveFeedback()

    }

    else {
      this.toastService.show(TOAST_TYPE.ERROR, 'Error', 'Please provide both Email and Phone.');
    }
  }
  onAddPhoneNumber(phone:string) {
    if (phone) {
      if (phone.length == 10) {
        this.phoneValid = true;
      } else {
        this.phoneValid = false;
        this.toastService.show(TOAST_TYPE.ERROR, 'Error',"Phone number must be 10 digit");
      }
      this.feedback.phone = phone;
    }
    else {
      this.phoneValid = true;
    }
  }
  ValidateEmail(email: string) {

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email) {
      if (email.match(validRegex)) {
        this.validateEmail = true;
      } else {

        this.validateEmail = false;
        this.toastService.show(TOAST_TYPE.ERROR, 'Error', " Email should be in format 'example@google.com'");

      }
    } else {
      this.validateEmail = true;
    }
  }
}
