<div>
    <div id="headerWrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 text-center mb-5">
                    <a href="/home" class="navbar-brand-privacy">
                        <img src="/assets/img/logo.png" class="img-fluid"
                            alt="logo">
                    </a>
                    <a href="/" class="back-button" style="transform: translate(-7%,-29%);"><i class="fa fa-chevron-left"></i></a>
                </div>
                <div class="col-md-12 col-sm-12 col-12 text-center">
                    <h2 class="main-heading">Terms And conditions</h2>
                </div>
            </div>
        </div>
    </div>
  
    <div id="privacyWrapper" class="">
        <div class="privacy-container">
            <div class="privacyContent">
  
                <div class="d-flex justify-content-between privacy-head">
                    <div class="privacyHeader">
                        <h1>Terms And conditions</h1>
                        <p>coming soon</p>
                    </div>
              </div>
              <div class="privacy-content-container">
                  <section>
                      <h1>coming soon...</h1>
                  </section>
                </div>
              
          </div>
          
      </div>
  </div>
  <br>
  <br><br>
  
  
                   <!--- <div class="get-privacy-terms align-self-center">
                        <button class="btn btn-primary" (click)="printPage()"><svg xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-printer">
                                <polyline points="6 9 6 2 18 2 18 9"></polyline>
                                <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2">
                                </path>
                                <rect x="6" y="14" width="12" height="8"></rect>
                            </svg> Print</button>
                    </div>
  
                </div>
  
                <div class="privacy-content-container">
  
                    <section>
                        <p>Thank you for choosing to be part of our community at buywork ("Company", "we", "us", "our").
                            We are committed to protecting your personal information and your right to privacy. If you
                            have any questions or concerns about this privacy notice, or our practices with regards to
                            your personal information, please contact us at support@buywork.in.
                        </p>
  
                        <p>When you visit our website https://buywork.in/ (the "Website"), use our mobile application,
                            use our Facebook application, as the case may be (the "App") and more generally, use any of
                            our services (the "Services", which include the Website and App), we appreciate that you are
                            trusting us with your personal information. We take your privacy very seriously. In this
                            privacy notice, we seek to explain to you in the clearest way possible what information we
                            collect, how we use it and what rights you have in relation to it. We hope you take some
                            time to read through it carefully, as it is important. If there are any terms in this
                            privacy notice that you do not agree with, please discontinue use of our Services
                            immediately.
  
                        </p>
  
                        <p>
                            This privacy notice applies to all information collected through our Services (which, as
                            described above, includes our Website and App), as well as, any related services, sales,
                            marketing or events.
  
                        </p>
  
                        <p>
                            Please read this privacy notice carefully as it will help you understand what we do with the
                            information that we collect.
  
                        </p>
  
  
                    </section>
                    <section>
                        <h5 class="policy-info-ques">What personal data we collect and why we collect it
                        </h5>
                        <p>
                            Please read this privacy notice carefully as it will help you understand what we do with the
                            information that we collect.
                        </p>
                    </section>
  
                </div>
  
                <div class="privacy-content-container">
  
                    <section>
                        <h5 class="policy-info-ques">TABLE OF CONTENTS
                        </h5>
                        <h6>
                            WHAT INFORMATION DO WE COLLECT?
                        </h6>
  
                        <h6>
                            <b>
                                Personal information you disclose to us
                            </b>
                        </h6>
                        <p>
                            <i>In Short: We collect personal information that you provide to us.</i>
                        </p>
  
                        <p>
                            We collect personal information that you voluntarily provide to us when you register on the
                            Services, express an interest in obtaining information about us or our products and
                            Services, when you participate in activities on the Services (such as by posting messages in
                            our online forums or entering competitions, contests or giveaways) or otherwise when you
                            contact us.
  
                        </p>
  
                        <p>
                            The personal information that we collect depends on the context of your interactions with us
                            and the Services, the choices you make and the products and features you use. The personal
                            information we collect may include the following:
                        </p>
  
                        <p>
                            <b>Personal Information Provided by You.</b> We collect phone numbers; names; email
                            addresses; usernames; billing addresses; debit/credit card numbers; and other similar
                            information.
  
                        </p>
  
                        <p>
                            <b>
                                Payment Data.
                            </b> We may collect data necessary to process your payment if you make purchases, such as
                            your payment instrument number (such as a credit card number), and the security code
                            associated with your payment instrument. All payment data is stored by paytm. You may find
                            their privacy notice link(s) here: https://pages.paytm.com/privacy.html.
  
                        </p>
                        <p>
                            All personal information that you provide to us must be true, complete and accurate, and you
                            must notify us of any changes to such personal information.
  
                        </p>
  
  
                    </section>
  
                    <section>
  
                        <h6>
                            Information collected through our App
                        </h6>
  
                        <h6>
                            <b>
                                <i>In Short: We collect information regarding your geo-location, mobile device, push
                                    notifications, and Facebook permissions when you use our App.
                                </i>
                            </b>
                        </h6>
                        <p>
                            If you use our App, we also collect the following information:
                        </p>
  
                        <ol>
                            <li>
                                Geo-Location Information. We may request access or permission to and track
                                location-based information from your mobile device, either continuously or while you are
                                using our App, to provide certain location-based services. If you wish to change our
                                access or permissions, you may do so in your device's settings.
                            </li>
  
                            <li>
                                Mobile Device Data. We automatically collect device information (such as your mobile
                                device ID, model and manufacturer), operating system, version information and system
                                configuration information, device and application identification numbers, browser type
                                and version, hardware model Internet service provider and/or mobile carrier, and
                                Internet Protocol (IP) address (or proxy server). If you are using our App, we may also
                                collect information about the phone network associated with your mobile device, your
                                mobile device’s operating system or platform, the type of mobile device you use, your
                                mobile device’s unique device ID and information about the features of our App you
                                accessed.
                            </li>
  
                            <li>
                                Push Notifications. We may request to send you push notifications regarding your account
                                or certain features of the App. If you wish to opt-out from receiving these types of
                                communications, you may turn them off in your device's settings.
                            </li>
                            <li>
                                Facebook Permissions. We by default access your Facebook basic account information,
                                including your name, email, gender, birthday, current city, and profile picture URL, as
                                well as other information that you choose to make public. We may also request access to
                                other permissions related to your account, such as friends, checkins, and likes, and you
                                may choose to grant or deny us access to each individual permission. For more
                                information regarding Facebook permissions, refer to the Facebook Permissions Reference
                                page.
                            </li>
                        </ol>
  
                        <p>
                            This information is primarily needed to maintain the security and operation of our App, for
                            troubleshooting and for our internal analytics and reporting purposes.
  
                        </p>
                    </section>
  
  
                    <section>
                        <h6>
                            HOW DO WE USE YOUR INFORMATION?
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: We process your information for purposes based on legitimate business
                                    interests, the fulfillment of our contract with you, compliance with our legal
                                    obligations, and/or your consent.
                                </i>
                            </b>
                        </h6>
                        <p>
                            We use personal information collected via our Services for a variety of business purposes
                            described below. We process your personal information for these purposes in reliance on our
                            legitimate business interests, in order to enter into or perform a contract with you, with
                            your consent, and/or for compliance with our legal obligations. We indicate the specific
                            processing grounds we rely on next to each purpose listed below.
                        </p>
                        <p>
                            We use the information we collect or receive:
                        </p>
                        <ol>
                            <li>
                                <b> To facilitate account creation and logon process.</b> If you choose to link your
                                account
                                with us to a third-party account (such as your Google or Facebook account), we use the
                                information you allowed us to collect from those third parties to facilitate account
                                creation and logon process for the performance of the contract.
                            </li>
                            <li>
                                <b> To post testimonials.</b> We post testimonials on our Services that may contain
                                personal information. Prior to posting a testimonial, we will obtain your consent to use
                                your name and the content of the testimonial. If you wish to update, or delete your
                                testimonial, please contact us at support@buywork.in and be sure to include your name,
                                testimonial location, and contact information.
                            </li>
                            <li>
                                <b> Request feedback.</b> We may use your information to request feedback and to contact
                                you about your use of our Services.
                            </li>
  
                            <li>
                                <b> To enable user-to-user communications.</b> We may use your information in order to
                                enable user-to-user communications with each user's consent.
                            </li>
  
                            <li>
                                <b> To manage user accounts</b> We may use your information for the purposes of managing
                                our account and keeping it in working order.
                            </li>
  
                            <li>
                                <b> To send administrative information to you. </b> We may use your personal information
                                to send you product, service and new feature information and/or information about
                                changes to our terms, conditions, and policies.
                            </li>
  
  
                            <li>
                                <b> To protect our Services. </b> We may use your information as part of our efforts to
                                keep our Services safe and secure (for example, for fraud monitoring and prevention).
                            </li>
  
                            <li>
                                <b> To enforce our terms, conditions and policies for business purposes, to comply with
                                    legal and regulatory requirements or in connection with our contract. </b>
                            </li>
  
                            <li>
                                <b> To respond to legal requests and prevent harm.</b>
                                If we receive a subpoena or other legal request, we may need to inspect the data we hold
                                to determine how to respond.
                            </li>
  
                            <li>
                                <b> Fulfill and manage your orders.</b>
                                We may use your information to fulfill and manage your orders, payments, returns, and
                                exchanges made through the Services
                            </li>
  
                            <li>
                                <b> Administer prize draws and competitions.</b>
                                We may use your information to administer prize draws and competitions when you elect to
                                participate in our competitions.
                            </li>
  
                            <li>
                                <b> To deliver and facilitate delivery of services to the user. </b>
                                We may use your information to provide you with the requested service.
                            </li>
  
  
                            <li>
                                <b> To respond to user inquiries/offer support to users. </b>
                                We may use your information to respond to your inquiries and solve any potential issues
                                you might have with the use of our Services.
                            </li>
  
  
                        </ol>
                    </section>
  
                    <section>
                        <h6>
                            WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: We only share information with your consent, to comply with laws, to
                                    provide you with services, to protect your rights, or to fulfill business
                                    obligations.
                                </i>
                            </b>
                        </h6>
                        <p>
                            We may process or share your data that we hold based on the following legal basis:
                        </p>
  
  
                        <ul>
                            <li>
  
                                <b>Consent :</b>
                                We may process your data if you have given us specific consent to use your personal
                                information for a specific purpose.
                            </li>
                        </ul>
  
  
                        <ul>
                            <li>
  
                                <b>Legitimate Interests :</b>
                                We may process your data when it is reasonably necessary to achieve our legitimate
                                business interests.
                            </li>
                        </ul>
  
                        <ol>
                            <li>
  
                                <b>Performance of a Contract :</b>
                                Where we have entered into a contract with you, we may process your personal information
                                to fulfill the terms of our contract.
                            </li>
                        </ol>
  
                        <ol>
                            <li>
  
                                <b>Legal Obligations :</b>
                                We may disclose your information where we are legally required to do so in order to
                                comply with applicable law, governmental requests, a judicial proceeding, court order,
                                or legal process, such as in response to a court order or a subpoena (including in
                                response to public authorities to meet national security or law enforcement
                                requirements).
                            </li>
                        </ol>
  
                        <ol>
                            <li>
  
                                <b>Vital Interests :</b>
                                We may disclose your information where we believe it is necessary to investigate,
                                prevent, or take action regarding potential violations of our policies, suspected fraud,
                                situations involving potential threats to the safety of any person and illegal
                                activities, or as evidence in litigation in which we are involved.
                            </li>
                        </ol>
                        <p>
                            More specifically, we may need to process your data or share your personal information in
                            the following situations:
                        </p>
                        <ol>
                            <li>
  
                                <b>Vital Interests :</b>
                                We may disclose your information where we believe it is necessary to investigate,
                                prevent, or take action regarding potential violations of our policies, suspected fraud,
                                situations involving potential threats to the safety of any person and illegal
                                activities, or as evidence in litigation in which we are involved.
                            </li>
                        </ol>
  
  
                        <ol>
                            <li>
  
                                <b>Business Transfers :</b>
                                We may share or transfer your information in connection with, or during negotiations of,
                                any merger, sale of company assets, financing, or acquisition of all or a portion of our
                                business to another company.
                            </li>
                        </ol>
  
                        <ol>
                            <li>
  
                                <b>Offer Wall :</b>
                                Our App may display a third-party hosted "offer wall." Such an offer wall allows
                                third-party advertisers to offer virtual currency, gifts, or other items to users in
                                return for the acceptance and completion of an advertisement offer. Such an offer wall
                                may appear in our App and be displayed to you based on certain data, such as your
                                geographic area or demographic information. When you click on an offer wall, you will be
                                brought to an external website belonging to other persons and will leave our App. A
                                unique identifier, such as your user ID, will be shared with the offer wall provider in
                                order to prevent fraud and properly credit your account with the relevant reward. Please
                                note that we do not control third-party websites and have no responsibility in relation
                                to the content of such websites. The inclusion of a link towards a third-party website
                                does not imply an endorsement by us of such website. Accordingly, we do not make any
                                warranty regarding such third-party websites and we will not be liable for any loss or
                                damage caused by the use of such websites. In addition, when you access any third-party
                                website, please understand that your rights while accessing and using those websites
                                will be governed by the privacy notice and terms of service relating to the use of those
                                websites.
                            </li>
                        </ol>
  
  
  
                    </section>
  
                    <section>
                        <h6>
                            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: We may use cookies and other tracking technologies to collect and store
                                    your information.
  
                                </i>
                            </b>
                        </h6>
                        <p>
                            We may use cookies and similar tracking technologies (like web beacons and pixels) to access
                            or store information. Specific information about how we use such technologies and how you
                            can refuse certain cookies is set out in our Cookie Notice.
  
                        </p>
                    </section>
                    <section>
                        <h6>
                            DO WE USE GOOGLE MAPS?
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: Yes, we use Google Maps for the purpose of providing better service.
                                </i>
                            </b>
                        </h6>
                        <p>
                            This Website or App uses Google Maps APIs which is subject to Google’s Terms of Service. You
                            may find the Google Maps APIs Terms of Service here. To find out more about Google’s Privacy
                            Policy, please refer to this link.
  
                        </p>
  
                        <p>
                            We use the Google Maps API to retrieve certain information when you make location-specific
                            requests. This includes:
                        </p>
  
                        <ul>
                            GEO LOCATION
                        </ul>
  
                        <p>
                            For a full list of what we use information for, please see the previous section titled "HOW
                            DO WE USE YOUR INFORMATION?" and "WILL YOUR INFORMATION BE SHARED WITH ANYONE?". We obtain
                            and store on your device ('cache') your location. You may revoke your consent anytime by
                            contacting us at the contact details provided at the end of this document.
  
                        </p>
  
                        <p>
                            The Maps APIs that we use store and access cookies and other information on your devices. If
                            you are a user currently in the European Economic Area (EU countries, Iceland, Liechtenstein
                            and Norway), please take a look at our Cookie Notice.
  
                        </p>
                    </section>
  
  
                    <section>
                        <h6>
                            HOW LONG DO WE KEEP YOUR INFORMATION?
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: We keep your information for as long as necessary to fulfill the purposes
                                    outlined in this privacy notice unless otherwise required by law.
                                </i>
                            </b>
                        </h6>
                        <p>
                            We will only keep your personal information for as long as it is necessary for the purposes
                            set out in this privacy notice, unless a longer retention period is required or permitted by
                            law (such as tax, accounting or other legal requirements). No purpose in this notice will
                            require us keeping your personal information for longer than the period of time in which
                            users have an account with us.
  
                        </p>
  
                        <p>
                            When we have no ongoing legitimate business need to process your personal information, we
                            will either delete or anonymize such information, or, if this is not possible (for example,
                            because your personal information has been stored in backup archives), then we will securely
                            store your personal information and isolate it from any further processing until deletion is
                            possible.
                        </p>
                    </section>
                    <section>
                        <h6>
                            HOW DO WE KEEP YOUR INFORMATION SAFE?
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: We aim to protect your personal information through a system of
                                    organizational and technical security measures.
                                </i>
                            </b>
                        </h6>
                        <p>
                            We have implemented appropriate technical and organizational security measures designed to
                            protect the security of any personal information we process. However, despite our safeguards
                            and efforts to secure your information, no electronic transmission over the Internet or
                            information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                            guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able
                            to defeat our security, and improperly collect, access, steal, or modify your information.
                            Although we will do our best to protect your personal information, transmission of personal
                            information to and from our Services is at your own risk. You should only access the
                            Services within a secure environment.
  
                        </p>
                    </section>
                    <section>
                        <h6>
                            DO WE COLLECT INFORMATION FROM MINORS?
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: We do not knowingly collect data from or market to children under 18 years
                                    of age.
  
                                </i>
                            </b>
                        </h6>
                        <p>
                            We do not knowingly solicit data from or market to children under 18 years of age. By using
                            the Services, you represent that you are at least 18 or that you are the parent or guardian
                            of such a minor and consent to such minor dependent’s use of the Services. If we learn that
                            personal information from users less than 18 years of age has been collected, we will
                            deactivate the account and take reasonable measures to promptly delete such data from our
                            records. If you become aware of any data we may have collected from children under age 18,
                            please contact us at support@buywork.in.
                        </p>
                    </section>
  
                    <section>
                        <h6>
                            WHAT ARE YOUR PRIVACY RIGHTS?
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: You may review, change, or terminate your account at any time.
                                </i>
                            </b>
                        </h6>
                        <p>
                            If you are a resident in the European Economic Area and you believe we are unlawfully
                            processing your personal information, you also have the right to complain to your local data
                            protection supervisory authority. You can find their contact details here:
                            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                        </p>
                        <p>
                            If you are a resident in Switzerland, the contact details for the data protection
                            authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.
                        </p>
                        <p>
                            If you have questions or comments about your privacy rights, you may email us at
                            support@buywork.in.
                        </p>
                    </section>
  
  
                    <section>
                        <h6>
                            Account Information
                        </h6>
  
                        <p>
                            If you would at any time like to review or change the information in your account or
                            terminate your account, you can:
                        </p>
  
                        <ul>
                            <li>
                                Contact us using the contact information provided.
                            </li>
                            <li>
                                Log in to your account settings and update your user account.
                            </li>
                        </ul>
  
                        <p>
                            Upon your request to terminate your account, we will deactivate or delete your account and
                            information from our active databases. However, we may retain some information in our files
                            to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms
                            of Use and/or comply with applicable legal requirements.
  
                        </p>
  
                        <p>
                            Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If
                            you prefer, you can usually choose to set your browser to remove cookies and to reject
                            cookies. If you choose to remove cookies or reject cookies, this could affect certain
                            features or services of our Services. To opt-out of interest-based advertising by
                            advertisers on our Services visit
                            http://www.aboutads.info/choices/.
                        </p>
  
                        <p>
                            Opting out of email marketing: You can unsubscribe from our marketing email list at any time
                            by clicking on the unsubscribe link in the emails that we send or by contacting us using the
                            details provided below. You will then be removed from the marketing email list — however, we
                            may still communicate with you, for example to send you service-related emails that are
                            necessary for the administration and use of your account, to respond to service requests, or
                            for other non-marketing purposes. To otherwise opt-out, you may:
  
                            <li>
                                Contact us using the contact information provided.
                            </li>
                        </p>
                    </section>
  
  
                    <section>
                        <h6>
                            CONTROLS FOR DO-NOT-TRACK FEATURES
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: Yes, if you are a resident of California, you are granted specific rights
                                    regarding access to your personal information.
                                </i>
                            </b>
                        </h6>
                        <p>
                            California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
                            users who are California residents to request and obtain from us, once a year and free of
                            charge, information about categories of personal information (if any) we disclosed to third
                            parties for direct marketing purposes and the names and addresses of all third parties with
                            which we shared personal information in the immediately preceding calendar year. If you are
                            a California resident and would like to make such a request, please submit your request in
                            writing to us using the contact information provided below.
  
                        </p>
  
                        <p>
                            If you are under 18 years of age, reside in California, and have a registered account with a
                            Service, you have the right to request removal of unwanted data that you publicly post on
                            the Services. To request removal of such data, please contact us using the contact
                            information provided below, and include the email address associated with your account and a
                            statement that you reside in California. We will make sure the data is not publicly
                            displayed on the Services, but please be aware that the data may not be completely or
                            comprehensively removed from all our systems (e.g. backups, etc.).
                        </p>
                    </section>
                    <section>
                        <h6>
                            DO WE MAKE UPDATES TO THIS NOTICE?
                        </h6>
                        <h6>
                            <b>
                                <i>In Short: Yes, we will update this notice as necessary to stay compliant with
                                    relevant laws.
  
                                </i>
                            </b>
                        </h6>
                        <p>
                            We may update this privacy notice from time to time. The updated version will be indicated
                            by an updated "Revised" date and the updated version will be effective as soon as it is
                            accessible. If we make material changes to this privacy notice, we may notify you either by
                            prominently posting a notice of such changes or by directly sending you a notification. We
                            encourage you to review this privacy notice frequently to be informed of how we are
                            protecting your information.
                        </p>
  
  
                    </section>
                    <section>
                        <h6>
                            DO WE MAKE UPDATES TO THIS NOTICE?
                        </h6>
  
                        <p>
                            Most web browsers and some mobile operating systems and mobile applications include a
                            Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference
                            not to have data about your online browsing activities monitored and collected. At this
                            stage no uniform technology standard for recognizing and implementing DNT signals has been
                            finalized. As such, we do not currently respond to DNT browser signals or any other
                            mechanism that automatically communicates your choice not to be tracked online. If a
                            standard for online tracking is adopted that we must follow in the future, we will inform
                            you about that practice in a revised version of this privacy notice.
                        </p>
                    </section>
                    <section>
                        <h6>
                            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </h6>
                        <p>
                            If you have questions or comments about this notice, you may email us at support@buywork.in
                            or by post to:
                        </p>
                        <p>
                            buywork
                            <br>
                            Delhi
                            <br>
                            DELHI 110001
                            <br>
                            India
                        </p>
                    </section>
                    <section>
                        <h6>
                            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                        </h6>
                        <p>
                            Based on the applicable laws of your country, you may have the right to request access to
                            the personal information we collect from you, change that information, or delete it in some
                            circumstances. To request to review, update, or delete your personal information, please
                            submit a request form by clicking here. We will respond to your request within 30 days.
                        </p>
  
                    </section>
  
  
  
  
                </div>
            </div>
  
        </div>
    </div>
  </div>-->
  
  <!--<div id="miniFooterWrapper" class="">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="position-relative">
                    <div class="arrow text-center">
                        <p class="">Up</p>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="col-lg-5 mx-auto col-lg-6 col-md-6 site-content-inner text-md-left text-center copyright align-self-center">
                        <p class="mt-md-0 mt-4 mb-0">&copy;<a target="_blank"
                                href="https://buywork.in/">2020 BUYWORK ALL RIGHTS RESERVED.</a></p>
                    </div>
                    <div
                        class="col-xl-5 mx-auto col-lg-6 col-md-6 site-content-inner text-md-right text-center align-self-center">
                        <p class="mb-0">buyWork.in G40 DELHI, NEW DELHI, INDIA 110001</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>-->
    <footer class="index-footer">
    <div class="container text-center">
      <div class="links">
        <a href="/about-us">About Us</a>
        <a href="/privacy">Privacy Policy</a>
        <a href="/team">Team</a>
        <a href="/user-feedback">Share Feedback</a>
        <a href="/helpdesk">Self Helpdesk</a>
        <a href="/faq">FAQ</a>
        <a href="/contact-us">Contact Us</a>
        <a href="/term-condition"> Terms and Conditions</a>
        <a href="/near-me">Near Me </a>
      </div>
      <div class="social-links">
        <a href="#"><i class="fab fa-twitter"></i></a>
        <a href="#"><i class="fab fa-facebook"></i></a>
        <a href="#"><i class="fab fa-youtube"></i></a>
        <a href="#"><i class="fab fa-linkendin"></i></a>
      </div>
      <span class="copyrights">© 2020 BUYWORK ALL RIGHTS RESERVED.</span>
      <div style="margin: 10px 0;"> 
          <img src="assets/img/visa.jpg" class="footer-img">
          <img src="assets/img/Mastercard_logo.jpg" class="footer-img">
          <img src="assets/img/maestro.jpg" class="footer-img">
          <img src="assets/img/Rupay.png" class="footer-img">
          <img src="assets/img/Net banking.jpg" class="footer-img">
          <img src="assets/img/Paytm-Logo.jpg" class="footer-img">
          <img src="assets/img/upi.png" class="footer-img">
          <img src="assets/img/discover.png" class="footer-img">
          <img src="assets/img/100-secure.jpg" class="footer-img">
          </div>
    </div>
  </footer>
  
    
  
  