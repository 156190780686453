import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageTranslatorService } from '../services/language-translator.service';

@Pipe({
  name: 'languageTranslator'
})
export class LanguageTranslatorPipe implements PipeTransform {

  constructor(private languageTranslatorService: LanguageTranslatorService) { }

  transform(value: string): Observable<string> {
    if (!environment.isLanguageTranslatorEnabled) {
      return of(value);
    }
    if (typeof value !== 'string') {
      value = value + "";
    }
    return this.languageTranslatorService.translateText(value);
  }

}
