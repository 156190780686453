import { Component, OnInit } from '@angular/core';
import { CanonicalService } from 'src/app/core/services/canonical.service';
import {AdminPushNotificationService} from "./services/admin-push-notification.service";

@Component({
  selector: 'app-admin-push-notification',
  templateUrl: './admin-push-notification.component.html',
  styleUrls: ['./admin-push-notification.component.scss']
})
export class AdminPushNotificationComponent implements OnInit {
  constructor(private service: AdminPushNotificationService,private canonicalService: CanonicalService) { }

  title = 'push-notification';
  message: any;

  ngOnInit() {
    this.canonicalService.setCanonicalURL();
    this.service.requestPermission();
    this.service.receiveMessage();
    this.message = this.service.currentMessage;
  }

}

