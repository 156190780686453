import { NgModule, Optional, SkipSelf } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { LayoutComponent } from './layout.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { SubNavComponent } from './components/sub-nav/sub-nav.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { EnsureLoadedOnceGuard } from '../ensure-loaded-once.guard';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { TooltipModule } from 'ng2-tooltip-directive';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FabComponent } from './components/fab/fab.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatsComponent } from './components/chats/chats.component';
import {Ng2SearchPipeModule} from "ng2-search-filter";


@NgModule({
  declarations: [LayoutComponent, TopNavComponent, SubNavComponent, SideNavComponent, LoginModalComponent, FabComponent, ChatsComponent],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        TooltipModule.forRoot({
            'z-index': 9999
        }),
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        NgxUiLoaderModule,
        NguiAutoCompleteModule,
        NgSelectModule,
        Ng2SearchPipeModule

    ],
  providers: [
    DatePipe
  ],
  exports: [LayoutComponent, FooterComponent]
})
export class LayoutModule extends EnsureLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: LayoutModule) {
    super(parentModule);
  }
}
