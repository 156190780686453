import { AfterViewInit, Directive, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appGridList]'
})
export class GridListDirective implements OnInit {

  @Input() classToAdd!: string;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
    let className = window.innerWidth > 640 ? 'list' : 'grid';
    this.updateDisplay(className);
  }

  @HostListener('click') onClick() {

    this.updateDisplay(this.classToAdd)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let className = event.target.innerWidth > 640 ? 'list' : 'grid';
    this.updateDisplay(className);
  }


  private updateDisplay(classToAdd: string) {
    let activeClass = 'active-view';

    let icons = document.getElementsByClassName('grid-list-icon');

    for (let index = 0; index < icons.length; index++) {
      this.renderer.removeClass(icons[index], activeClass);
    }

    let searchableContainer = document.getElementsByClassName('searchable-container');
    if (searchableContainer) {
      for (let x = 0; x < searchableContainer.length; x++) {
        const element = searchableContainer[x];
        this.renderer.removeClass(element, 'list');
        this.renderer.removeClass(element, 'grid');
        this.renderer.addClass(element, classToAdd);
      }
    }

    let searchableItems = document.getElementsByClassName('searchable-items');
    if (searchableItems) {
      for (let y = 0; y < searchableItems.length; y++) {
        const element = searchableItems[y];
        this.renderer.removeClass(element, 'list');
        this.renderer.removeClass(element, 'grid');
        this.renderer.addClass(element, classToAdd);
      }
    }

    let currentElement = document.querySelector(`.view-${classToAdd}`);
    if (currentElement)
      this.renderer.addClass(currentElement, activeClass);
  }

}
