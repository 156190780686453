<ng-template #content let-modal>
    <div class="modal-content">
        <div class="modal-body justify-content-center">
            <div class="row">
                <div class="col-md-12">
                    <p class="text-right">Completetion Date:
                        {{task.targetCompletionDate | date:'M/d/yyyy h:mm a'}}</p>
                </div>
            </div>
            <div class="form-row mb-2">
                <div class="col-md-12">
                    <label class="mb-0" style="font-size: 16px"><strong>Task: </strong></label>
                    <p style="text-indent: 1rem;">
                        {{task?.taskTitle}}
                    </p>
                </div>

            </div>

            <div class="form-row mb-2">
                <div class="col-md-12">
                    <label class="mb-0" style="font-size: 16px"><strong>Description: </strong></label>
                    <p class="text-wrap text-justify" style="text-indent: 1rem;" [innerHTML]="task?.taskDescription">

                    </p>
                </div>
            </div>
            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area space vertical-space">
                    <div id="example-vertical" role="application" class="circle wizard clearfix vertical">
                        <div class="steps clearfix">
                            <ul role="tablist">
                              
                                <li role="tab" class="current" aria-disabled="false" aria-selected="true"><a
                                        id="example-vertical-t-1" aria-controls="example-vertical-p-1"><span
                                            class="current-info audible">current
                                            step:
                                        </span><span class="number">New</span></a></li>

                                <li role="tab" class="first done" aria-disabled="false" aria-selected="false"><a
                                        id="example-vertical-t-0" aria-controls="example-vertical-p-0"><span
                                            class="number">Progress</span></a>
                                </li>
                                <li role="tab" class="last done" aria-disabled="false" aria-selected="false"><a
                                        id="example-vertical-t-2" aria-controls="example-vertical-p-2"><span
                                            class="number">Finish</span></a>
                                </li>
                            </ul>
                        </div>
                        <div class="content clearfix">
                            <textarea class="form-control w-100 h-100" minlength="10" maxlength="2500" name="index"
                                placeholder="Enter Your Comment"></textarea>



                        </div>
                        <div class="w-100 d-flex justify-content-between mt-4">
                            <button class="btn btn-primary">Previous</button>
                            <button class="btn btn-primary">Next</button>


                        </div>
                    </div>
                </div>
            </div>



        </div>
        <div class="modal-footer justify-content-center">
            <button type="button" class="btn" (click)="closeModal()">Close</button>
        </div>


    </div>
</ng-template>