import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUserNotificationResponse } from '../models/user-notification-reponse.model';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  private url: string = environment.API_URL + '/Notification';


  constructor(private http: HttpClient) { }

  getNotificationsByUserDetails(userId: number, sortOrder: string, sortColumn: string, pageSize: number, pageNum: number) {
    return this.http.get(`${this.url}/GetNotificationsbyUserDetails?userId=${userId}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&pageSize=${pageSize}&pageNum=${pageNum}`);
  }

  getNotificationsByUser(userId: number, recordCount: number): Observable<IUserNotificationResponse> {
    return this.http.get<IUserNotificationResponse>(`${this.url}/GetNotificationsbyUser?userId=${userId}&recordCount=${recordCount}`);
  }
}
