<div class="fq-header-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 mr-auto mt-5 text-md-left text-center">
          <a href="/home" class="ml-md-5">
            <img alt="image-500" style="width: 70px;height:70px" src="/assets/img/logo.png" class="theme-logo">
          </a>
         
          <a href="/" class="back-button" style="transform: translate(-7%,-29%);"><i class="fa fa-chevron-left"></i></a>
  
  
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 align-self-center order-md-0 order-1">
          <h1 class="">FAQ</h1>
          <p class="">Get Instant Answer From Popular Question Asked By Other Users</p>
          <button class="btn">Start Learning</button>
        </div>
        <div class="col-md-6 order-md-0 order-0">
          <a target="_blank" href="#" class="banner-img">
            <img src="/assets/img/faq.svg" class="d-block" alt="header-image">
          </a>
        </div>
      </div>
    </div>
  </div>
  
  <div class="faq container">
  
    <div class="faq-layouting layout-spacing">
  
  
      <div class="fq-comman-question-wrapper">
        <div class="row">
          <div class="col-md-12">
            <h3>Installation Based Problems</h3>
            <div class="row">
              <div class="col-md-6">
                <ul class="">
                  <li class="list-unstyled">
                    <div class="icon-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    How to Install CORK
                  </li>
                  <li class="list-unstyled">
                    <div class="icon-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    Gulp not running
                  </li>
                  <li class="list-unstyled">
                    <div class="icon-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    Browser Sync not working
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="">
                  <li class="list-unstyled">
                    <div class="icon-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    File Strucutre
                  </li>
                  <li class="list-unstyled">
                    <div class="icon-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    Demo Detail
                  </li>
                  <li class="list-unstyled">
                    <div class="icon-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    Build website and webapps
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="fq-tab-section">
        <div class="row">
          <div class="col-md-12 mb-5 mt-5">
  
            <h2>Some common questions</h2>
  
            <div class="accordion" id="accordionExample">
                <div class="card" *ngFor="let faq of faqs; let i = index;">
                  <div class="card-header" [attr.id]="'fqheading' + i">
                    <div class="mb-0" data-bs-toggle="collapse" role="navigation" [attr.data-bs-target]="'#fqcollapse' + i"
                      aria-expanded="true"  [attr.aria-controls]="'fqcollapse' + i">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-code">
                        <polyline points="16 18 22 12 16 6"></polyline>
                        <polyline points="8 6 2 12 8 18"></polyline>
                      </svg> <span class="faq-q-title">{{faq.quest}}</span>
                      <div class="like-faq"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                          viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="feather feather-thumbs-up">
                          <path
                            d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3">
                          </path>
                        </svg> <span class="faq-like-count">65</span></div>
                    </div>
                  </div>
                  <div [id]="'fqcollapse' + i" class="collapse" [attr.aria-labelledby]="'fqheading' + i"
                    data-bs-parent="#accordionExample">
                    <div class="card-body">
                      <p>{{faq.ans}}</p>
                    </div>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
  
      <div class="fq-article-section">
        <h2>Popular Topics</h2>
        <div class="row">
          <div class="col-lg-3 col-md-6 mb-lg-0 mb-4">
            <div class="card">
              <img src="/assets/img/400x300.jpg" class="card-img-top" alt="faq-video-tutorials">
              <div class="card-body">
                <div class="fq-rating">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                </div>
                <h5 class="card-title">Coding Angular</h5>
                <p class="card-text">Some quick example text to build on the card title.</p>
                <p class="meta-text"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-calendar">
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg> May 6, 2020</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-lg-0 mb-4">
            <div class="card">
              <img src="/assets/img/400x300.jpg" class="card-img-top" alt="faq-video-tutorials">
              <div class="card-body">
                <div class="fq-rating">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                </div>
                <h5 class="card-title">Creative Photography</h5>
                <p class="card-text">Some quick example text to build on the card title.</p>
                <p class="meta-text"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-calendar">
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg> June 9, 2020</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-md-0 mb-4">
            <div class="card">
              <img src="/assets/img/400x300.jpg" class="card-img-top" alt="faq-video-tutorials">
              <div class="card-body">
                <div class="fq-rating">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                </div>
                <h5 class="card-title">Trending Style</h5>
                <p class="card-text">Some quick example text to build on the card title.</p>
                <p class="meta-text"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-calendar">
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg> July 6, 2020</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="card">
              <img src="/assets/img/400x300.jpg" class="card-img-top" alt="faq-video-tutorials">
              <div class="card-body">
                <div class="fq-rating">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star checked">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-star">
                    <polygon
                      points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                    </polygon>
                  </svg>
                </div>
                <h5 class="card-title">Latest Tweet</h5>
                <p class="card-text">Some quick example text to build on the card title.</p>
                <p class="meta-text"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-calendar">
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg> July 7, 2020</p>
              </div>
            </div>
          </div>
        </div>
  
      </div>
  
    </div>
  </div>
  
  <!--<div id="miniFooterWrapper" class="">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12">
          <div class="position-relative">
            <div class="arrow text-center">
              <p class="">Up</p>
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-5 mx-auto col-lg-6 col-md-6 site-content-inner text-md-left text-center copyright align-self-center">
              <p class="mt-md-0 mt-4 mb-0">2020 &copy; <a target="_blank" href="https://buywork.in/">buyWork.in</a>.</p>
            </div>
            <div
              class="col-xl-5 mx-auto col-lg-6 col-md-6 site-content-inner text-md-right text-center align-self-center">
              <p class="mb-0">buyWork.in G40 DELHI, NEW DELHI, INDIA 110001</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <footer class="index-footer">
    <div class="container text-center">
      <div class="links">
        <a href="/about-us">About Us</a>
        <a href="/privacy">Privacy Policy</a>
        <a href="/team">Team</a>
        <a href="/user-feedback">Share Feedback</a>
        <a href="/helpdesk">Self Helpdesk</a>
        <a href="/faq">FAQ</a>
        <a href="/contact-us">Contact Us</a>
        <a href="/term-condition"> Terms and Conditions</a>
        <a href="/near-me">Near Me </a>
      </div>
      <div class="social-links">
        <a href="#"><i class="fab fa-twitter"></i></a>
        <a href="#"><i class="fab fa-facebook"></i></a>
        <a href="#"><i class="fab fa-youtube"></i></a>
        <a href="#"><i class="fab fa-linkendin"></i></a>
      </div>
      <span class="copyrights">© 2020 BUYWORK ALL RIGHTS RESERVED.</span>
      <div style="margin: 10px 0;"> 
        <img src="assets/img/visa.jpg" class="footer-img">
        <img src="assets/img/Mastercard_logo.jpg" class="footer-img">
        <img src="assets/img/maestro.jpg" class="footer-img">
        <img src="assets/img/Rupay.png" class="footer-img">
        <img src="assets/img/Net banking.jpg" class="footer-img">
        <img src="assets/img/Paytm-Logo.jpg" class="footer-img">
        <img src="assets/img/upi.png" class="footer-img">
        <img src="assets/img/discover.png" class="footer-img">
        <img src="assets/img/100-secure.jpg" class="footer-img">
        </div>
    </div>
  </footer>
  