import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationModel } from '../models/location.model';
import { Geolocation } from '@capacitor/geolocation';
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  getLocation(): Observable<LocationModel> {
    return this.http.get<LocationModel>('https://geolocation-db.com/json/');
  }

  // getPosition(): Promise<LocationModel> {
  //   return new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(resp => {
  //       resolve({ longitude: resp.coords.longitude, latitude: resp.coords.latitude });
  //     },
  //       err => {
  //         reject(err);
  //       });
  //   });

  // }

  async getPosition() {
    // return await Geolocation.getCurrentPosition();
    const position = await Geolocation.getCurrentPosition();

    // grab latitude & longitude
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    return { longitude, latitude } as LocationModel;
  }
}
