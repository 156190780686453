<div class="header-container">
    <header class="header navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
            <li class="nav-item theme-logo">
                <a routerLink="/">
                    <img src="/assets/img/logo.png" class="navbar-logo"
                        alt="logo">
                </a>
            </li>
            <li class="nav-item theme-text">
                <a routerLink="/" class="nav-link">buyWork</a>
            </li>
        </ul>

        <ul class="navbar-item flex-row ml-md-0 ml-auto">
            <li class="nav-item align-self-center search-animated" [class.show-search]="isActive">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    (click)="toggleSearch(true)" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather
          feather-search toggle-search">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <form class="form-inline search-full form-inline search" [class.input-focused]="isActive" role="search">
                    <div class="search-bar topnav-searchbar">
                        <input placement="right" type="text" name="keyword"
                            class="form-control search-form-control ml-lg-auto" auto-complete z-index="1000"
                            [source]="observableSource.bind(this)" [list-formatter]="myValueFormatter" [min-chars]="1"
                            value-property-name="name" [close-on-focusout]="false" (valueChanged)="onSelectTask($event)"
                            display-property-name="name" placeholder="Search...">
                    </div>
                </form>
            </li>
        </ul>

        <ul class="navbar-item flex-row ml-md-auto">

            <li ngbDropdown class="nav-item dropdown language-dropdown">
                <a ngbDropdownToggle style="line-height: 1;" href="javascript:void(0);" class="nav-link dropdown-toggle"
                    id="language-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="language-switch-icon"><i class="fas fa-language"></i></span>
                </a>
                <div ngbDropdownMenu class="dropdown-menu position-absolute" aria-labelledby="language-dropdown">
                    <a ngbDropdownItem class="dropdown-item d-flex btn" *ngFor="let language of languages"
                        href="javascript:void(0);">
                        <i (click)="changeLanguage(language)">
                            <span class="language-icon">A</span>
                            <span class="text">{{language.name}}</span>
                        </i></a>

                </div>
            </li>

            <li ngbDropdown style="margin-left: 15px; " class="nav-item dropdown message-dropdown">
                <a ngbDropdownToggle tooltip="{{ 'Click to view Inbox messages' | languageTranslator | async}}"
                    placement="bottom" [show-delay]="500" href="javascript:void(0);" (click)="getNotifications()"
                    class="nav-link dropdown-toggle" id="messageDropdown" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-mail">
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0
              1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                    </svg><span class="notification-number"
                        *ngIf="userNotification?.notificationsDetails.length">{{userNotification.notificationsDetails.length}}</span>
                </a>
                <div ngbDropdownMenu class="dropdown-menu p-0 position-absolute" aria-labelledby="messageDropdown">
                    <div class="">
                        <a class="dropdown-item" *ngFor="let notification of userNotification?.notificationsDetails">
                            <div class="">

                                <div class="media">
                                    <div class="user-img">
                                        <img class="usr-img rounded-circle"
                                            src="/assets/img/logo.png"
                                            alt="profile">
                                    </div>
                                    <div class="media-body">
                                        <div class="">
                                            <h5 class="usr-name">{{notification?.notificationSubject}}</h5>
                                            <p class="msg-title" [innerHTML]="notification?.notificationBody"></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </a>
                        <div class="moreNotifications">
                            <a class="notif-link" routerLink="/user-notification">
                                <p *ngIf="notificationsExists">...more</p>
                                <p *ngIf="!notificationsExists">Check notifications if any...</p>
                            </a>
                        </div>
                    </div>
                </div>
            </li>

            <li class="nav-item dropdown notification-dropdown">
                <a tooltip="{{ 'Have come alerts, Please check' | languageTranslator | async}}" placement="bottom"
                    [show-delay]="500" placement="left" [show-delay]="500" (click)="notify()" href="javascript:void(0);"
                    class="nav-link dropdown-toggle" id="notificationDropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-bell">
                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3
              9h18s-3-2-3-9"></path>
                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg><span class="badge badge-success"></span>
                </a>
            </li>

            <li class="nav-item d-flex align-items-center ml-1">
                <span class="text-white user-no d-none d-md-block d-lg-block d-xl-block" *ngIf="isAuthenticated">
                    Hi {{currentUser.userName}}
                </span>
            </li>
            <li ngbDropdown class="nav-item dropdown user-profile-dropdown">
                <a ngbDropdownToggle tooltip="{{ 'User profile options' | languageTranslator | async}}"
                    placement="bottom" [show-delay]="500" href="javascript:void(0);"
                    class="nav-link dropdown-toggle user userlogin" id="userProfileDropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="true">


                    <i class="fas fa-user-lock" *ngIf="!isAuthenticated" (click)="login()"> </i>
                    <img [src]="imageUrl"
                        onerror="this.src='/assets/img/loggedin.png';"
                        alt="avatar" *ngIf="isAuthenticated">
                </a>
                <div ngbDropdownMenu class="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown"
                    *ngIf="isAuthenticated">
                    <div class="">
                        <div class="dropdown-item">
                            <a class="" href="javascript:void(0);" (click)="navigateProfile()"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg> My Profile</a>
                        </div>
                        <div class="dropdown-item">
                            <a class="" href="javascript:void(0);" (click)="navigateInvoice()"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg> My Invoices</a>
                        </div>

                        <div class="dropdown-item">
                            <a class="" href="javascript:void(0);" (click)="lockScreen()"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-lock">
                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg> Lock Screen</a>
                        </div>
                        <div class="dropdown-item">

                            <a class="" href="javascript:void(0);" (click)="signOut()"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-log-out">
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                    <polyline points="16 17 21 12 16 7"></polyline>
                                    <line x1="21" y1="12" x2="9" y2="12"></line>
                                </svg> Sign Out</a>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </header>

</div>

<div class="search-overlay" [class.show]="isActive" (click)="toggleSearch(false)"></div>