
  <azure-map *ngIf="!currentLocation" [autoResize]="true" cursor="pointer" [view]="'auto'" [mapStyle]="'road'" [showBuildingModels]="true"
      [dataSources]="[route]" (onReady)="mapReady($event)" [trafficOptions]="trafficOptions" [zoom]="16">
      <map-html-marker  *ngFor="let point of points" [position]="point"></map-html-marker>
      <map-line-layer dataSourceId="route" [strokeWidth]="strokeWidth"></map-line-layer>
      <map-zoom-control position='top-left'></map-zoom-control>
      <map-pitch-control position="top-left"></map-pitch-control>
      <map-compass-control position="top-left"></map-compass-control>
      <map-style-control position="top-left"></map-style-control>
  </azure-map>


  <azure-map *ngIf="currentLocation" [autoResize]="true" [mapStyle]="'road'"
                 [center]="[currentLocation.longitude,currentLocation.latitude]" [showBuildingModels]="true"
                 [zoom]="16" (onReady)="mapReady($event)" [dataSources]="[route]">
        <map-html-marker [position]="[currentLocation.longitude,currentLocation.latitude]"></map-html-marker>
        <map-zoom-control position='top-left'></map-zoom-control>
        <map-pitch-control position="top-left"></map-pitch-control>
        <map-compass-control position="top-left"></map-compass-control>
        <map-style-control position="top-left"></map-style-control>
  </azure-map>



