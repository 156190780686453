export class Dictionary {
    items:any = {};
    constructor() {
      this.items = {};
    }
    public has(key: string) {
      return key in this.items;
    }
    public set(key: string | number, value: any) {
      this.items[key] = value;
    }
    public get(key: string | number) {
      return this.items[key];
    }
    public delete(key: string) {
      if (this.has(key)) {
        delete this.items[key]
        return true;
      }
      return false;
    }
  }
  