import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService) { }

  show(type: TOAST_TYPE, title: string, message: string) {
    this.toastr.clear();

    switch (type) {
      case TOAST_TYPE.SUCCESS:
        this.toastr.success(message, title)
        break;
      case TOAST_TYPE.INFO:
        this.toastr.info(message, title)
        break;
      case TOAST_TYPE.WARNING:
        this.toastr.warning(message, title)
        break;
      case TOAST_TYPE.ERROR:
        this.toastr.error(message, title)
        break;
      default:
        break;
    }

  }
}

export enum TOAST_TYPE {
  SUCCESS = 'sucess',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}