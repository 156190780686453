import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { AuthStorageService } from '../services/auth-storage.service';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private token_added: string = 'VIokmJ9BIzGjeKaFNLMmYGE5Xy1F52JV';

  constructor(private authStorage: AuthStorageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authStorage.getToken() && request.url.toLowerCase().indexOf(environment.API_URL.toLowerCase()) > -1) {
      return next.handle(this.addAuthToken(request)).pipe(
        catchError((err: HttpErrorResponse) => {
          this.authStorage.purge();
          return next.handle(request);

        })
      );
    }
    else {
      
      if (request.urlWithParams.toLowerCase().includes("userid=0")) {
        return EMPTY;
      }
      // if (request.body) {
      //   alert(1);
      //   debugger;
      //   return EMPTY;
      // }

      return next.handle(request);
    }

  }

  private addAuthToken(request: HttpRequest<any>) {
    let token = this.authStorage.getParseToken().user_id + this.token_added;
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }
}
