 <div class="modal-content">
      <div class="modal-body">
        <svg (click)="closeModal()" style="position: absolute; right: 10px; top: 10px; cursor: pointer;"
             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-x">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>

        <div class="d-flex align-items-center">
        <div class="text-center mx-4 header-title">
          <h1 class="">{{ 'Sign In' }}</h1>
          <p class="">{{ "Add your phone number . We'll send you a verification code so we now you are real " }}</p>
        </div>
      </div>
        <form *ngIf="!isSendedCode">
        <div id="form_container" style="width: 428px; height: 245px;">
          <div class="input_row_container">
            <div class="input_row"><div class="input_label">{{'Phone Number'}}</div>
              <div class="field has-addons input_text_container">
                <div class="control">
                  <div class="select country_code">
                    <select [(ngModel)]="countryCode" [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let code of countryCodeList" [value]="code">{{code}}</option>

                    </select></div>
                </div>
                <p class="control input_text_p">
                  <input type="text" class="input_text" id="phone_input" placeholder="2345678910" [(ngModel)]="phoneNumber" [ngModelOptions]="{standalone: true}">
                </p>
              </div>
            </div>
            <div class="input_label error" style="color: red;" *ngIf="errorMessage">
              {{errorMessage}}
            </div>
            <div class="button_container" style="background-color: rgb(59, 85, 134); border: none;" (click)="sendOtp()">
              <div class="button_text" style="color: rgb(255, 255, 255);" >{{'Send Otp'}}
              </div>
            </div>
            <div class="terms_text"> By providing my phone number  i hereby agree and accept the
              <a href="https://buywork.in/faq" rel="noopener noreferrer" target="_blank" style="color:rgb(59, 85, 134);">
                Terms of Service</a> and
              <a href="https://buywork.in/privacy" rel="noopener noreferrer" target="_blank" style="color:rgb(59, 85, 134);">Privacy Policy</a>.
              in use of the Buywork app
            </div>
          </div>
        </div>
      </form>
        <form *ngIf="isSendedCode" (ngSubmit)="confirmCode(confirmCodeForm.value)" [formGroup]="confirmCodeForm">
          <div id="form_container" style="width: 340px;">
            <div class="input_label verification_label">
              Verify Phone Number
            </div>
            <div class="pin_row" id="cotter_pin_row">
              <div formArrayName="digits">
                <input *ngFor="let field of getControls();
                   let i = index"
                       #inputs
                       [type]="isPlatform ? 'number': 'text'"
                       class="pin_input"
                       [maxLength]="1"
                       [formControlName]="i"
                       (keydown)="check(i, field, $event)">
              </div>
            </div>
            <div class="input_label verification_subtitle">
              {{' Enter the verification code sent to'}} {{this.countryCode.toString()  + this.phoneNumber.toString()}}
              <span class="back_button" (click)="back()">{{'change'}}</span>
            </div>
            <div class="input_label error" style="color: rgb(125, 68, 250); text-align: center;">
            </div>
            <div class="button_container" style="background-color: rgb(59, 85, 134); border: none;" (click)="confirmCode(confirmCodeForm.value)">
              <div class="button_text" style="color: rgb(255, 255, 255);" >{{'Continue'}}</div>
            </div>
            <div class="button_resend resend_active" (click)="sendOtp()"><i class="fas fa-redo"></i>{{'Resend Code'}} </div>
          </div>
        </form>

      </div>
    </div>
