import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageTranslatorPipe } from './pipes/language-translator.pipe';
import { HttpClientModule } from '@angular/common/http';
import { LanguageTranslatorService } from './services/language-translator.service';
import { AccordionDirective } from './directives/accordion.directive';
import { GridListDirective } from './directives/grid-list.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgbModalModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskTrackModalComponent } from './components/task-track-modal/task-track-modal.component';
import { VerificationModalComponent } from './components/verification-modal/verification-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { PageSelectionModalComponent } from './components/page-selection-modal/page-selection-modal.component';
import {FooterComponent} from '../layout/components/footer/footer.component';
import {TooltipModule} from 'ng2-tooltip-directive';
import { AzureMapComponent } from './components/azure-map/azure-map.component';
import { AzureMapsModule } from 'ng-azure-maps';
import {AuthenticationType} from "azure-maps-control";
import {environment} from "../../environments/environment";


@NgModule({
  declarations: [
    LanguageTranslatorPipe,
    AccordionDirective,
    GridListDirective,
    PaginationComponent,
    TaskTrackModalComponent,
    VerificationModalComponent,
    PageSelectionModalComponent,
    FooterComponent,
    AzureMapComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgbPaginationModule,
    NgbModalModule,
    NgSelectModule,
    TooltipModule,
    AzureMapsModule.forChild({
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        clientId: environment.AZURE_MAPS_AUTH_OPTIONS.clientId,
        subscriptionKey: environment.AZURE_MAPS_AUTH_OPTIONS.subscriptionKey
      }
    }),
  ],
  exports: [LanguageTranslatorPipe, AccordionDirective, TooltipModule, GridListDirective, PaginationComponent, TaskTrackModalComponent, VerificationModalComponent, PageSelectionModalComponent, FooterComponent, AzureMapComponent],
  providers: [LanguageTranslatorService]

})
export class SharedModule { }
