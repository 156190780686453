import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dictionary } from './dictionary';
import { ToastService, TOAST_TYPE } from './toast.service';
import { INotificationHubModel } from '../models/notificatio-hub-model';
import { NotificationTopicName } from './notification-topic-enum';

@Injectable({
    providedIn: 'root'
})
export class NotificationHubService {

    private url: string = "https://obs-azurestorage-svc.azurewebsites.net/api" + '/Topic';
    constructor(private http: HttpClient, private toastr: ToastService) {
    }

    notificationHubModel: INotificationHubModel = {
        topicName: "",
        payload: "",
        subscribeType: ""
    }




    public sendNotification(
        fromUserId: string,
        toUserId?: string,
        taskId?: string,
        title?: string,
        subscribeType?: string,
        notificationType?: number

    ) {
        let payload = new Dictionary();
        payload.set('fromUserId', fromUserId);
        payload.set('toUserId', toUserId);

        this.sendToNotificationMsg(
            subscribeType,
            payload.items,
            notificationType);
    }


    private sendToNotificationMsg(subscribeType: string, payLoad: any, notificationTypeId: number) {
        if (subscribeType != undefined && payLoad != null) {
            let body = {
                topicName: NotificationTopicName.mytopic,
                subscribeType: subscribeType,
                notificationTypeId: notificationTypeId,
                payload: payLoad

            };

            this.http.post(this.url, body)
                .subscribe({
                    next: data => {
                         this.toastr.show(TOAST_TYPE.SUCCESS, "Success","Notification Sent");
                    },
                    error: error => {
                      console.log(error);
                         this.toastr.show(TOAST_TYPE.ERROR, "Error", "Notification error");
                    }
                });
        }
    }

}


