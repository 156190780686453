import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ICommonUser } from 'src/app/core/models/common-user.model';
import { AuthStorageService } from 'src/app/core/services/auth-storage.service';
import { PreferencesNotifierService } from 'src/app/core/services/preferences-notifier.service';
import { SignalrService } from 'src/app/core/services/signalr.service';
import { ToastService, TOAST_TYPE } from 'src/app/core/services/toast.service';
import { TaskCategoryService } from 'src/app/features/task-categories/services/task-category.service';
import { UserService } from 'src/app/features/users/services/user.service';
import { environment } from "../../../../environments/environment";


@Component({
  selector: 'sub-nav',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.scss']
})
export class SubNavComponent implements OnInit {

  @Output() onToggleSideMenu: EventEmitter<void> = new EventEmitter();
  @Input() isAuthenticated: boolean = false;
  @Input() title: string = '';
  @ViewChild('addPreferences') addPreferences!: ElementRef;
  notificationHighLights: string[];
  autoCompleteList: any = [];

  options = {
    width: '300',
    multiple: true,
    tags: true,
  };

  selectedPreferences: string[] = [];
  savedSelctedPreferences: string[];
  commonUser: ICommonUser;
  isProcessing = false;
  messages: any;

  constructor(private router: Router,
    private categoryService: TaskCategoryService, private toastService: ToastService,
    private accountstore: AuthStorageService, private service: UserService,
    public signalRService: SignalrService,
    private preferencesNotifierService: PreferencesNotifierService,
    public modalService: NgbModal,
    private ngxLoader: NgxUiLoaderService,
    config: NgbCarouselConfig) {
    config.interval = environment.notificationHeaderInterval;
  }

  ngOnInit(): void {
    this.commonUser = this.accountstore.getCommonUserInfo();
    if (this.commonUser.userId > 0) {
      this.loadCategories();
      this.getPubMessages();
    }

  }

  toggleSideNav() {
    this.onToggleSideMenu.emit();
  }
  openPreferecesModel() {
    this.modalService.open(this.addPreferences, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

  }
  onCancelDiscard() {
    this.modalService.dismissAll()
  }

  getUserNotificationHeader() {
    if (this.commonUser?.userId > 0) {
      this.signalRService.getUserNotificationHeader(this.commonUser.userId).subscribe(data => {
        this.notificationHighLights = data as string[];
      });
    }
  }
  validateSpecialCharacter(event: any) {
    let k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  loadCategories() {
    this.categoryService.getTaskCategoriesOnlyActive().subscribe(data => {
      this.autoCompleteList = data.map((item) => {
        return {
          id: item.categoryId.toString(),
          text: item.categoryName
        };
      });
      this.getUserPreferences();
    }, (error) => {
      this.toastService.show(TOAST_TYPE.ERROR, 'Error', 'Unable to load Categories');
    });
  }
  getUserPreferences() {
    this.service.getUserPreferences(this.commonUser.userId).subscribe((res: any) => {
      this.selectedPreferences = [];
        this.savedSelctedPreferences = res;
      res.forEach((e: { category: any; }) => {
        let id = this.autoCompleteList.filter((x: { text: any; }) => x.text == e.category)[0].id;
        this.selectedPreferences.push(id);
      });
    })
  }
  onPreferenceValueValueChanged(data: any) {
    this.selectedPreferences = data;
    if (this.selectedPreferences != undefined && this.savedSelctedPreferences != undefined) {
      this.savedSelctedPreferences.forEach((e: any) => {
        var id = this.autoCompleteList.filter((x: { text: any; }) => x.text == e.category)[0].id;
        if (this.selectedPreferences.filter(x => x == id).length == 0) {
          this.isProcessing = true;
          this.service.updatePreferences({ id: e.id, userId: this.commonUser.userId, isActive: false, categoryId: id }).subscribe((res: any) => {
            this.toastService.show(TOAST_TYPE.SUCCESS, 'Success', 'Preference deleted successfully');
            this.savedSelctedPreferences = this.savedSelctedPreferences.filter((x: any) => x.category == e.category);
            this.isProcessing = false;
          }, (err: any) => {
            this.toastService.show(TOAST_TYPE.SUCCESS, 'Success', 'Unable to deleted preference');
            this.isProcessing = false;
          })
        }
      });
    }
  }


 
  submitPreferences() {
    if (this.selectedPreferences.length > 0) {
      this.ngxLoader.start();

      this.service.addUserPreferences({ userId: this.commonUser.userId, categoryId: this.selectedPreferences }).subscribe((res: any) => {
        this.toastService.show(TOAST_TYPE.SUCCESS, 'Success', 'Preferences added or Modified successfully');
        this.getUserPreferences();
        this.ngxLoader.stop();
        this.onCancelDiscard()      // this.toastService.showError('Please select atleast one category to save the preferences', 'Error');
        this.preferencesNotifierService.preferences.next(this.selectedPreferences);
       }, (err) => {
        this.toastService.show(TOAST_TYPE.ERROR, 'Error', `Unable to save preference`);
        this.ngxLoader.stop();
        this.onCancelDiscard()      // this.toastService.showError('Please select atleast one category to save the preferences', 'Error');
      })
    } else {
      if (this.savedSelctedPreferences && this.savedSelctedPreferences.length > 0) {
        this.ngxLoader.start();
        let successStatus: any = [];
        let i = 0;
        var len = this.savedSelctedPreferences.length;
        this.savedSelctedPreferences.forEach((e: any) => {
          successStatus.push(false);
          var id = this.autoCompleteList.filter((x: { text: any; }) => x.text == e.category)[0].id;
          this.service.updatePreferences({ id: e.id, userId: this.commonUser.userId, isActive: false, categoryId: id }).subscribe((res: any) => {
            this.savedSelctedPreferences = this.savedSelctedPreferences.filter((x: any) => x.category == e.category);
            successStatus[i] = true;
            i++;
            if (len == successStatus.filter((x: any) => x).length) {
              this.toastService.show(TOAST_TYPE.SUCCESS, 'Success', 'Preference deleted successfully');
              this.onCancelDiscard()      // this.toastService.showError('Please select atleast one category to save the preferences', 'Error');
              this.ngxLoader.stop();
              this.preferencesNotifierService.preferences.next(this.selectedPreferences);
            }
          }, (err) => {
            //console.log(err);
            successStatus[i] = true;
            i++;
            if (len == successStatus.filter((x: any) => x).length) {
              this.toastService.show(TOAST_TYPE.SUCCESS, 'Success', 'Preference deleted successfully');
              this.onCancelDiscard()      // this.toastService.showError('Please select atleast one category to save the preferences', 'Error');
              this.ngxLoader.stop();
              this.preferencesNotifierService.preferences.next(this.selectedPreferences);
            }
          });
        });
      }

       this.onCancelDiscard()      // this.toastService.showError('Please select atleast one category to save the preferences', 'Error');
    }
  }

  clearPreference() {
    this.selectedPreferences = [];
  }

  private getPubMessages(): void {
    this.service.getUserNotification(this.accountstore.getCommonUserInfo().userId).subscribe(res => {
      this.messages = res;
    });
  }
}
