import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IMessage } from './message';
import { ITaskCountModel } from './task-count.model';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  public data: IMessage;
  public bradcastedData: IMessage;
  private hubConnection: signalR.HubConnection

  private url: string = environment.API_URL;

  constructor(private http: HttpClient) { }
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('https://buywork-ds.azurewebsites.net/notify', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      //.withAutomaticReconnect()
      .build();
    this.hubConnection
      .start()
      .then(() => { 
        //console.log('Connection started')
       }
      )
      .catch(err => console.log('Error while starting connection: ' + err));
  }

  public GetTaskCountperUserId(userId: number): Observable<ITaskCountModel> {
    return this.http.get<ITaskCountModel>(this.url + '/Task/GetNotificationTasksCount?UserId=' + userId);
  }

  //


  public getUserNotificationHeader(userId: number): Observable<string[]> {
    return this.http.get<string[]>(this.url + '/NotifyHub/GetNotificationForHeader?UserId=' + userId);
  }
  public addTransferChartDataListener = () => {
    this.hubConnection.on('transferdata', (data:any) => {
      this.data = data;
      //console.log(data);
    });
  }
}
