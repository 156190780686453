<div class="sidebar-wrapper sidebar-theme">
  <nav id="sidebar">
    <ul class="list-unstyled menu-categories" id="accordionExample">
      <li class="menu">
        <a
          appAccordion
          classToAdd="show"
          targetId="pick-task"
          commonClass="submenu"
          href="javascript:void(0);"
          data-toggle="collapse"
          data-active="true"
          aria-expanded="true"
          class="dropdown-toggle collapsed"
        >
          <div class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-navigation"
            >
              <polygon points="3 11 22 2 13 21 11 13 3 11"></polygon>
            </svg>
            <span
              tooltip="{{ 'Quick Pick/Drop' | languageTranslator | async }}"
              placement="bottom"
              [show-delay]="500"
            >
              {{ "Quick Options" | languageTranslator | async }}</span
            >
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
        </a>
        <ul
          class="collapse submenu x show list-unstyled"
          id="pick-task"
          data-parent="#accordionExample"
        >
          <li>
            <a
              tooltip="{{
                'Click to new task near by you' | languageTranslator | async
              }}"
              placement="top"
              [show-delay]="500"
              routerLink="/my-tasks"
            >
              <i class="fa-file far"></i
              >{{ "Pick Task" | languageTranslator | async }}</a
            >
          </li>
          <li *ngIf="isAuthenticated">
            <a
              tooltip="{{
                'Click to create new task' | languageTranslator | async
              }}"
              placement="top"
              [show-delay]="500"
              routerLink="/tasks"
            >
              <i class="fa-edit far"></i
              >{{ "Create Task" | languageTranslator | async }}</a
            >
          </li>
        </ul>
      </li>

      <li class="menu" *ngIf="isAuthenticated">
        <a
          appAccordion
          classToAdd="show"
          targetId="app"
          commonClass="submenu"
          href="javascript:void(0);"
          data-toggle="collapse"
          aria-expanded="true"
          data-active="true"
          class="dropdown-toggle collapsed"
        >
          <div class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-grid"
            >
              <rect x="3" y="3" width="7" height="7"></rect>
              <rect x="14" y="3" width="7" height="7"></rect>
              <rect x="14" y="14" width="7" height="7"></rect>
              <rect x="3" y="14" width="7" height="7"></rect>
            </svg>
            <span>{{ "Menu" | languageTranslator | async }}</span>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
        </a>
        <ul
          class="collapse submenu list-unstyled show"
          id="app"
          data-parent="#accordionExample"
        >
          <li
            *ngFor="let menu of menus"
            [class.active]="currentRoute === '/' + menu.route"
          >
            <a
              tooltip="{{ menu.text | languageTranslator | async }}"
              placement="top"
              [show-delay]="500"
              [routerLink]="menu.route"
            >
              <i class="{{ menu.icon }}"></i
              >{{ menu.text | languageTranslator | async }}</a
            >
          </li>
        </ul>
      </li>

      <li class="menu">
        <a
          appAccordion
          classToAdd="show"
          targetId="help-desk-page"
          commonClass="submenu"
          href="javascript:void(0);"
          data-toggle="collapse"
          data-active="true"
          aria-expanded="true"
          class="dropdown-toggle collapsed"
        >
          <div class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-zap"
            >
              <polygon
                points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"
              ></polygon>
            </svg>
            <span>{{ "Help Desk" | languageTranslator | async }}</span>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
        </a>
        <ul
          class="collapse submenu list-unstyled show"
          id="help-desk-page"
          data-parent="#accordionExample"
        >
          <li>
            <a
              tooltip="{{ 'Click for Helpdesk' | languageTranslator | async }}"
              placement="right"
              [show-delay]="500"
              routerLink="/privacy"
              ><i class="far fa-compass"></i>
              {{ "Privacy Info" | languageTranslator | async }}
            </a>
          </li>
          <li>
            <a
              tooltip="{{
                'Click for user feedback' | languageTranslator | async
              }}"
              placement="right"
              [show-delay]="500"
              routerLink="/user-feedback"
              ><i class="far fa-building"></i>
              {{ "Share Feedback" | languageTranslator | async }}
            </a>
          </li>
          <li>
            <a
              tooltip="{{ 'Click for Helpdesk' | languageTranslator | async }}"
              placement="right"
              [show-delay]="500"
              routerLink="/helpdesk"
              ><i class="far fa-clipboard"></i>
              {{ "Helpdesk" | languageTranslator | async }}
            </a>
          </li>
          <li>
            <a
              tooltip="{{ 'Click for FAQ' | languageTranslator | async }}"
              placement="right"
              [show-delay]="500"
              routerLink="/faq"
              ><i class="far fa-hourglass"></i>
              {{ "FAQ" | languageTranslator | async }}
            </a>
          </li>
          <li>
            <a
              tooltip="{{
                'Click for Contact Us' | languageTranslator | async
              }}"
              placement="right"
              [show-delay]="500"
              routerLink="/contact-us"
              ><i class="far fa-thumbs-up"></i>
              {{ "Contact Us" | languageTranslator | async }}
            </a>
          </li>
          <li>
            <a
              tooltip="{{
                'Click to view team page' | languageTranslator | async
              }}"
              placement="right"
              [show-delay]="500"
              routerLink="/team"
              ><i class="far fa-user"></i>
              {{ "buyWork Team" | languageTranslator | async }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>
