import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {


  private loginSource = new BehaviorSubject<boolean>(false);
  onLoginEvent = this.loginSource.asObservable();

  constructor() { }

  forceLogin() {
    this.loginSource.next(true);
  }

}
