import { Component, OnInit } from '@angular/core';
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.scss']
})
export class TermConditionComponent implements OnInit {

  constructor(private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
  }

}
