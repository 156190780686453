import { Component, OnInit } from '@angular/core';
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-maintenence',
  templateUrl: './maintenence.component.html',
  styleUrls: ['./maintenence.component.scss']
})
export class MaintenenceComponent implements OnInit {

  constructor(private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
  }

}
