import { environment } from "src/environments/environment";

export class FileSizeHelper {
    static validateFileSize(bytes: number, decimals: number): boolean {
        if (bytes == 0) return false;
        var k = environment.KBSize,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        if (sizes[i] === 'Bytes' || sizes[i] === 'KB')
            return true;

        if (sizes[i] === 'MB') {
            if (parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) < environment.ImageSize) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }
}