import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as moment from "moment";
import jwt_decode from 'jwt-decode';
import { ICommonUser } from '../models/common-user.model';
import { IStytchToken } from "../../features/login/models/stytch.model";

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  queryUserId?: any;
  TOKEN_NAME: string = environment.token_storage_service.bw_ST_token_name;
  bw_ST_token_expiry_duration: number = environment.token_storage_service.bw_ST_token_expiry_duration;


  constructor() { }

  setToken(token: string): void {
    localStorage.setItem(this.TOKEN_NAME, token);
  }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_NAME)!;
  }

  getParseToken(): IStytchToken {
    const token = JSON.parse(this.getToken());
    return token?.payload;
  }

  isAuthenticated(): boolean {
    return moment().isBefore(this.getExpiration());

  }

  isAdmin(): boolean {
    let currentUser = this.getCommonUserInfo();
    if (!currentUser) return false;
    return currentUser.isAdmin;
  }

  getExpiration() {
    let expiration = this.bw_ST_token_expiry_duration;
    const token = JSON.parse(this.getToken());
    if (token) {
      expiration = token?.exp;
    }
    return moment(expiration);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token) as any;
    if (decoded.exp === undefined) return null!;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  public getUserProfile(): any {
    const userProfile = JSON.parse(this.getToken());
    return userProfile?.user_profile;
  }

  getCommonUserInfo(): ICommonUser {
    var tokenData = this.getParseToken();
    let commonUser: ICommonUser = {
      emailId: '',
      roles: [],
      userId: 0,
      userName: '',
      customRoles: [],
      locale: '',
      name: '',
      nickname: '',
      picture: '',
      sub: '',
      updatedAt: '',
      timeZone: '',
      customPermission: [],
      customGroup: [],
      country: '',
      givenName: '',
      familyName: '',
      isAdmin: false,
      phoneNumber: '',
    };
    if (tokenData) {
      if (this.isAuthenticated()) {
        const user = this.getUserProfile();
        commonUser.userId = user.userId || 0;
        commonUser.roles = user.roles || [];
        commonUser.locale = user?.locale;
        commonUser.name = user?.name;
        commonUser.nickname = user?.phone;
        commonUser.picture = user?.picture;
        commonUser.sub = user?.sub;
        // commonUser.timeZone = user[AUTH_CONFIG.TIMEZONE] || '';
        // commonUser.customPermission = user[AUTH_CONFIG.CUSTOMPERMISSION] || '';
        // commonUser.customGroup = user[AUTH_CONFIG.CUSTOMGROUP] || '';
        commonUser.updatedAt = user?.updatedAt;
        commonUser.phoneNumber = user?.phone;
        commonUser.userName = user?.phone;
        commonUser.country = user?.country;
        commonUser.givenName = user?.given_name;
        commonUser.familyName = user?.phone;

        if (commonUser.roles.length > 0) {
          commonUser.isAdmin = commonUser.roles.findIndex(e => e?.toLowerCase() === environment.USER_ROLES.ADMIN) > -1;
        } else {
          commonUser.roles.push(environment.USER_ROLES.USER);
        }
      }
      else {
        this.purge();
      }
    }
    return commonUser;
  }


  purge(): void {
    localStorage.removeItem(this.TOKEN_NAME);
  }
}
