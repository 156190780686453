<!-- Modal -->
<ng-template #content let-modal>
    <div class="modal-content">
        <div class="modal-body justify-content-center">
            <h4 class="text-center">{{ 'Where do you want to go?' | languageTranslator | async}}</h4>
            <svg (click)="closeModal()" style="position: absolute; right: 10px; top: 10px; cursor: pointer;"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
            <div class="d-flex justify-content-center mt-4 btn-container">
                <button class="btn btn-primary mx-1" placement="left" show-delay="500"
                    (click)="goToTasksPage()">Picker</button>
                <button class="btn btn-primary mx-1" placement="left" show-delay="500"
                    (click)="goToCreateTask()">Dropper</button>
              <span>{{timer}}</span>
            </div>

        </div>
    </div>
</ng-template>
