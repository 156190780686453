<div class="contact-us">
    <div class="cu-contact-section">    
        <div class="cu-section-header">
            <a href="/my-tasks" class="">
                <img alt="image-500" style="width: 62px;height: 62px; transform: translate(57%,-8%);" src="/assets/img/logo.png" class="theme-logo">
            </a>

            <a href="/" class="back-button"><i class="fa fa-chevron-left"></i></a>

            <h4 style="transform: translate(4%,-14%);">Feedback</h4>
            <p style="transform: translate(4%,-17%);">Submit your feedback and we will get back <br/> to you as soon as possible.</p>
        </div>
        <div id="feedback-div"></div>
        <div class="contact-form">
            <form class="">
                <h4>Send us a Feedback</h4>
                <div class="row mb-4">
                    <div class="col-sm-12 col-12 input-fields mb-4 mb-sm-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <input type="text" class="form-control" placeholder="Name" [(ngModel)] ="feedback.name" name="name">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-sm-12 col-12 input-fields">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-at-sign"><circle cx="12" cy="12" r="4"></circle><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path></svg>
                        <input type="text" class="form-control" placeholder="Email" #email (change)="ValidateEmail(email.value)" [(ngModel)] ="feedback.email" name="email">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-sm-12 col-12 input-fields">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                        <input type="text" class="form-control" placeholder="Phone"  #phone (change)="onAddPhoneNumber(phone.value)" [(ngModel)] ="feedback.phone" name="phone">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-sm-12 col-12 mb-2">
                        <p class="">Preffered method of communication</p>
                    </div>
                    <div class="col-sm-12 col-12 input-fields">
                        <div class="n-chk">
                            <label class="new-control new-radio radio-primary">
                              <input type="radio" class="new-control-input" name="custom-radio-1" checked="">
                              <span class="new-control-indicator"></span>Email
                            </label>
                        </div>
                        <div class="n-chk">
                            <label class="new-control new-radio radio-primary">
                              <input type="radio" class="new-control-input" name="custom-radio-1" checked="">
                              <span class="new-control-indicator"></span>Phone
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group input-fields">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Message" name="message" [(ngModel)] ="feedback.message"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-sm-left text-center">
                        <button class="btn btn-primary mt-4" (click)="Submit()">Submit</button>
                    </div>
                </div>
            </form>

        </div>


    </div>
</div>
<footer class="index-footer">
    <div class="container text-center">
      <div class="links">
        <a href="/about-us">About Us</a>
        <a href="/privacy">Privacy Policy</a>
        <a href="/team">Team</a>
        <a href="/user-feedback">Share Feedback</a>
        <a href="/helpdesk">Self Helpdesk</a>
        <a href="/faq">FAQ</a>
        <a href="/contact-us">Contact Us</a>
        <a href="/term-condition"> Terms and Conditions</a>
        <a href="/near-me">Near Me </a>
      </div>
      <div class="social-links"> <a href="#"><i class="fab fa-twitter"></i></a> <a href="#"><i
            class="fab fa-facebook"></i></a> <a href="#"><i class="fab fa-youtube"></i></a> <a href="#"><i
            class="fab fa-linkendin"></i></a> </div>
      <span class="copyrights">© 2020 BUYWORK ALL RIGHTS RESERVED.</span>
      <div style="margin: 10px 0;"> 
        <img src="assets/img/visa.jpg" class="footer-img">
        <img src="assets/img/Mastercard_logo.jpg" class="footer-img">
        <img src="assets/img/maestro.jpg" class="footer-img">
        <img src="assets/img/Rupay.png" class="footer-img">
        <img src="assets/img/Net banking.jpg" class="footer-img">
        <img src="assets/img/Paytm-Logo.jpg" class="footer-img">
        <img src="assets/img/upi.png" class="footer-img">
        <img src="assets/img/discover.png" class="footer-img">
        <img src="assets/img/100-secure.jpg" class="footer-img">
        </div>
    </div>
  </footer>
