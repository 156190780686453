// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_URL: 'https://mytask-w-dev.api-werkbite.com/api',

  firebaseConfig: {
    apiKey: 'AIzaSyAXkOxIzFtwt2-pjZo0lt1Pf2cQqlrFRxc',
    authDomain: 'buywork-d5714.firebaseapp.com',
    databaseURL: 'https://buywork-d5714.firebaseio.com',
    projectId: 'buywork-d5714',
    storageBucket: 'buywork-d5714.appspot.com',
    messagingSenderId: '30613068734',
    appId: '1:30613068734:web:1edb0e21c580c7e6a7f7ef',
    measurementId: 'G-287M2JT36T'
  },
  HOME_PAGE_CONSTANTS: {
    sitename: 'buyWork',
    siteRated: '5',
    satisfiedhomes: '99,00000',
    inhouseProfessionals: '77,00000',
    backgroundChecked: '1000'
  },
  FEATURE_SERVICES: [
    { title: 'Appliances Repair', imageUrl: '/assets/img/maintenance.svg' },
    { title: 'Cleaning', imageUrl: '/assets/img/vacuum-cleaner.svg' },
    { title: 'AC Repair', imageUrl: '/assets/img/ac.svg' },
    { title: 'Electronics', imageUrl: '/assets/img/plug.svg' },
    { title: 'Plumber & Carpenter', imageUrl: '/assets/img/worker.svg' },
    { title: 'Makeup at home', imageUrl: '/assets/img/makeup.svg' },
    { title: 'Therapeutic Massage', imageUrl: '/assets/img/spa.svg' },
    { title: 'Painter', imageUrl: '/assets/img/painter.svg' },
    { title: 'Yoga', imageUrl: '/assets/img/stretching.svg' },
    { title: 'Disinfection', imageUrl: '/assets/img/sick.svg' },
  ],
  ALL_SERVICES: [
    { title: 'AC Service and Repair', imageUrl: '/assets/img/outline.svg' },
    { title: 'RO or Water Purifier Repair', imageUrl: '/assets/img/water-tank.svg' },
    { title: 'Washing Machine Service & Repair', imageUrl: '/assets/img/laundry.svg' },
    { title: 'Refrigerator Repair', imageUrl: '/assets/img/freezer.svg' },
    { title: 'Car Cleaning', imageUrl: '/assets/img/car.svg' },
    { title: 'Bathroom Deep Cleaning', imageUrl: '/assets/img/bathroom.svg' },
    { title: 'Pest control', imageUrl: '/assets/img/pesticide.svg' },
    { title: 'Sofa Cleaning', imageUrl: '/assets/img/living-room.svg' },
  ],

  COTTER_API_KEY: 'a69decae-7acf-4f46-92a6-4707f1a7cc4d',
  token_storage_service: {
    bw_token_user_expiry: 'bw_token_user_expiry',
    bw_token_user_profile: 'bw_token_user_profile',
    bw_ST_token_user_profile: 'bw_ST_token_user_profile',
    bw_ST_token_user_expiry: 'bw_ST_token_user_expiry',
    bw_ST_token_expiry_duration: 1000,
    bw_token_expiry_duration: 1000,
    bw_isCotter: 'bw_cotter',
    bw_user_profile: 'bw_user_profile',
    bw_ST_token_name: 'bw_ST_UserJwt'
  },

  expirationInHours: 24,
  USER_ROLES: {
    ADMIN: 'administrator',
    USER: 'user'
  },

  supportedLanguage: [{
    name: 'English',
    value: 'en',
    image: 'language.png'
  },
  {
    name: 'Hindi',
    value: 'hi',
    image: 'hindi.png'
  },
  {
    name: 'Marathi',
    value: 'mr',
    image: 'marathi.jpg'
  },
  {
    name: 'Gujarati',
    value: 'gu',
    image: 'marathi.jpg'
  },
  {
    name: 'Kannada',
    value: 'kn',
    image: 'marathi.jpg'
  },
  {
    name: 'Malay',
    value: 'ms',
    image: 'marathi.jpg'
  },
  {
    name: 'Punjabi',
    value: 'pa',
    image: 'marathi.jpg'
  },
  {
    name: 'Urdu',
    value: 'ur',
    image: 'marathi.jpg'
  }



  ],
  paymentDoneStatus: 'paid',
  domainName: 'buywork.in',
  paymentWarningNote: 'Only release milestone payments when you are 100% satisfied with your task. They cannot be returned once released.',
  azureTranslatorKey: 'd3b26cc4f68242d999c6447140224259',
  isLanguageTranslatorEnabled: true,
  azureUrl: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=',
  azureRegion: 'centralindia',
  LaunchEpoc: 1598922000000,
  currentPage: 1,
  currency: 'INR',
  payment_capture: 1,
  AdminRole: 'administrator',
  promocodeSuccessMessage: 'PromoCode applied successfully',
  promocodeFailureMessage: 'Invalid PromoCode',

  razorPayConfig: {
    razorKey: 'rzp_test_RYFPQjK4Ro4Jq0',
    razorPwd: 'cjw9VBCRfsgB8mAQVC6TrCuW',
    razorPayOrderUrl: 'https://api.razorpay.com/v1/orders',
    razorPayUIColor: '#F37254',
    logoUrl: '',
    amountForCalculation: 100
  },
  defaultForQuickTaskCreation: {
    priority: 3,
    privacy: 3,
    payment: 1,
    priceDefault: 10,
    maxDays: 30,
    status: 1,
    duration: 7,
    completionDays: 7,
    otherCategory: 13,
    priceMax: 50000,
    minDuration: 1,
  },
  ADMIN_MENU: [
    { route: 'dashboard', icon: 'far fa-chart-bar', text: 'Dashboard', isDisplay: true },
    { route: 'spog', icon: 'fa-solid fa-file-chart-pie', text: 'SPOG', isDisplay: true },
    { route: 'chat', icon: 'far fa-comments', text: 'Chat', isDisplay: true },
    { route: 'admin-notification', icon: 'fa fa-object-ungroup', text: 'Admin-notification', isDisplay: true },
    { route: 'contacts', icon: 'far fa-user', text: 'Contacts', isDisplay: true },
    { route: 'my-tasks', icon: 'far fa-file', text: 'My Tasks', isDisplay: true },
    { route: 'groups', icon: 'far fa-folder', text: 'Groups', isDisplay: true },
    { route: 'notifications', icon: 'far fa-bell', text: 'Notifications', isDisplay: true },
    { route: 'notify', icon: 'far fa-columns', text: 'Notify', isDisplay: true },
    { route: 'payment-types', icon: 'fa fa-credit-card', text: 'Payment Types', isDisplay: true },
    { route: 'privacy-type', icon: 'fa fa-universal-access', text: 'Privacy Type', isDisplay: true },
    { route: 'roles', icon: 'fa fa-calculator', text: 'Roles', isDisplay: true },
    { route: 'tasks', icon: 'far far fa-edit', text: 'Tasks', isDisplay: true },
    { route: 'task-priorities', icon: 'fa fa-volume-up', text: 'Task Priorities', isDisplay: true },
    { route: 'task-proposals', icon: 'far far fa-lightbulb', text: 'Task Proposals', isDisplay: true },
    { route: 'task-categories', icon: 'far fa-hourglass', text: 'Task Categories', isDisplay: true },
    { route: 'task-status', icon: 'far fa-list-alt', text: 'Task Status', isDisplay: true },
    { route: 'transaction-types', icon: 'far fa-money-bill-alt', text: 'Transaction Types', isDisplay: true },
    { route: 'users', icon: 'fa fa-user-plus', text: 'Users', isDisplay: true },
    { route: 'user-role', icon: 'far fa-lightbulb', text: 'User Role', isDisplay: true },
    { route: 'user-notification', icon: 'far fa-envelope', text: 'User Notification', isDisplay: true },
    { route: 'admin-feedback', icon: 'fa fa-rss', text: 'Feedback', isDisplay: true },
    { route: 'notification-type', icon: 'far fa-lightbulb', text: 'Notification Type', isDisplay: true },
    { route: 'user-photo', icon: 'far fa-lightbulb', text: 'User Photo', isDisplay: true },
    { route: 'promo-code', icon: 'far fa-dot-circle', text: 'Promo Code', isDisplay: true },
    { route: 'admin-contact-us', icon: 'far fa-square', text: 'Admin contact-us', isDisplay: true },
    { route: 'push', icon: 'far fa-bullhorn', text: 'Push notification', isDisplay: true },
    { route: 'admin-invoice', icon: 'far fa-save', text: 'Invoice List', isDisplay: true },
    { route: 'taskdetails', text: '', isDisplay: false },
    { route: 'profile', text: '', isDisplay: false },
    { route: 'invoice', text: '', isDisplay: false },
    { route: 'account-setting', text: '', isDisplay: false },
    { route: 'payment', text: '', isDisplay: false },
    { route: 'success', text: '', isDisplay: false },
    { route: 'failure', text: '', isDisplay: false },
  ],

  USER_MENU: [
    { route: 'dashboard', icon: 'far fa-chart-bar', text: 'Dashboard', isDisplay: false },
    { route: 'spog', icon: 'far fa-badge-percent', text: 'SPOG', isDisplay: false },
    { route: 'chat', icon: 'far fa-comments', text: 'Chat', isDisplay: true },
    { route: 'contacts', icon: 'far fa-user', text: 'Contacts', isDisplay: true },
    { route: 'my-tasks', icon: 'far fa-file', text: 'My Tasks', isDisplay: true },
    { route: 'groups', icon: 'far fa-folder', text: 'Groups', isDisplay: true },
    { route: 'notifications', icon: 'far fa-bell', text: 'Notifications', isDisplay: true },
    { route: 'tasks', icon: 'far far fa-edit', text: 'Tasks', isDisplay: true },
    { route: 'user-notification', icon: 'far fa-envelope', text: 'User Notification', isDisplay: true },

    { route: 'task-proposals', icon: 'far far fa-lightbulb', text: 'Task Proposals', isDisplay: false },
    { route: 'user-photo', icon: 'far fa-lightbulb', text: 'User Photo', isDisplay: false },

    { route: 'promo-code', icon: 'far fa-dot-circle', text: 'Promo Code', isDisplay: false },


    { route: 'profile', text: '', isDisplay: false },
    { route: 'invoice', text: '', isDisplay: false },
    { route: 'account-setting', text: '', isDisplay: false },
    { route: 'payment', text: '', isDisplay: false },
    { route: 'taskdetails', text: '', isDisplay: false },
    { route: 'success', text: '', isDisplay: false },
    { route: 'failure', text: '', isDisplay: false },
  ],

  AZURE_MAPS_AUTH_OPTIONS: {
    authType: 'subscriptionKey',
    clientId: '26e97e66-885c-4a11-bbe8-e81610b2b2a3',
    subscriptionKey: '1Nt3Qa3Vc00aFklzTPVFiVnvZaPWdkzmGxaV2sxECV0'

  },

  DEFAULTS_FOR_TASK_CREATION: {
    priority: 3,
    privacy: 3,
    payment: 1,
    priceDefault: 10,
    maxDays: 30,
    status: 1,
    duration: 7,
    completionDays: 7,
    otherCategory: 13,
    priceMax: 50000,
    minDuration: 7,
  },

  recordCount: 8,
  SizeErrorMessage: 'Image Must be in .jpg, .jpeg, .png format',
  AllowedFileType: ['jpg', 'png', 'jpeg'],
  ImageSize: 2,
  KBSize: 1024,
  pageSize: 15,
  loggedInImage: '/assets/img/loggedin.png',
  logoImage: '/assets/img/logo.png',
  stytchToken: 'public-token-test-57d628fb-9287-4126-a786-fdba844dbd2c',

  fireBaseUpdate: true,
  defaultLocation: {latitude: 28.61, longitude: 77.23215, address: 'India Gate, New Delhi, Delhi'},
  notificationHeaderInterval: 5000,

  adminNotificationType: [
    { id: 1, value: 'Missing Task Info' },
    { id: 2, value: 'Bill Generated' },
    { id: 3, value: 'Update Aadharcard' },
    { id: 4, value: 'Update your location for better proposal' },
    { id: 5, value: 'New task created' },
    { id: 6, value: 'Day spending' },
    { id: 7, value: 'Update your emailaddress' },
    { id: 8, value: 'Interested in task' },
    { id: 9, value: 'Update proposal type' },
    { id: 10, value: 'Update payment type' },
    { id: 11, value: 'Update price' },
    { id: 12, value: 'Task status change with comment' },
    { id: 13, value: 'Task complete' },
    { id: 14, value: 'As kfor payment' },
    { id: 15, value: 'Send rating to user' },
    { id: 16, value: 'Not reachable mobile no' },
    { id: 17, value: 'Update status about work' },
    { id: 18, value: 'Payment done' },
    { id: 19, value: 'Rating given by dropper' },
    { id: 20, value: 'Thank you message' },
    { id: 21, value: 'User inactive lock' },
    { id: 22, value: 'Billing charges discussion' },
    { id: 23, value: 'Picker not per quality' },
    { id: 24, value: 'Urgent call' }
  ],
  userNotificationType: [

    { id: 1, value: 'Missing Task Info' },
    { id: 2, value: 'Bill Generated' },
    { id: 5, value: 'New task created' }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
