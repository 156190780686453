import { Component, OnInit } from '@angular/core';
import { CanonicalService } from '../../services/canonical.service';
import { FAQService } from '../../services/faq.service';
import { IFaqPrivacyModel } from '../models/faq-privacy.model';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqs: IFaqPrivacyModel[] = [];
  public isCollapsed = false;

  constructor(private faqService: FAQService, private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();

    this.faqService.getAll().subscribe((response) => {
      this.faqs = response;
    }, (error) => {
      console.error(error);
    })
  }

}
