import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { IFeedbackModel } from '../pages/models/feedback.model';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    private readonly url: string = environment.API_URL + '/FeedBack';

    constructor(private http: HttpClient) { }

    add(feedback: IFeedbackModel) {
        return this.http.post(this.url, feedback);
    }
}
