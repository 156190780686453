import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AzureMapsModule } from 'ng-azure-maps';
import { AuthenticationType } from 'azure-maps-control';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
// @ts-ignore
import firebase from 'firebase/app';
import { AdminPushNotificationComponent } from './features/admin-push-notification/admin-push-notification.component';

firebase.initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [
    AppComponent,
    AdminPushNotificationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 2000
    }),

    AzureMapsModule.forRoot({
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        clientId: environment.AZURE_MAPS_AUTH_OPTIONS.clientId,
        subscriptionKey: environment.AZURE_MAPS_AUTH_OPTIONS.subscriptionKey
      }
    }),

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
