import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeleteModel } from 'src/app/shared/models/delete.model';
import { environment } from 'src/environments/environment';
import { IGetTaskTrack } from '../../my-tasks/models/get-task-track';
import { ISearchResult } from '../models/search-result.model';
import { ITaskImage } from '../models/task-image.model';
import { ITaskRequest } from '../models/task-request.model';
import { ITaskResponse } from '../models/task-response.model';
import { ITaskTrackResponse } from '../models/task-track.model';
import { ITask } from '../models/task.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private url: string = environment.API_URL + '/Task';
  private getImageurl: string = environment.API_URL + '/TaskImages/GetImage';
  private deleteImageurl: string = environment.API_URL + '/TaskImages/many-delete-user-task-images';
  private AddImageurl: string = environment.API_URL + '/TaskImages';
  private getTasksUrl: string = environment.API_URL + "/Task/GetAllServer";
  private taskTrackingUrl: string = environment.API_URL + '/TaskTracking';
  private checkTaskCreatedBySameUserUrl = environment.API_URL +'/Task/CheckTaskCreatedBySameUser';
  private getTaskById: string = environment.API_URL +  '/Task';
  private getInvoiceByTaskIdUrl: string = environment.API_URL + '/Invoice/GetInvoiceId';
  private taslTrackingUrl: string = environment.API_URL + '/TaskTracking';

  constructor(private http: HttpClient) { }

  add(model: ITask) {
    return this.http.post(this.url, model);
  }

  update(model: ITask) {
    return this.http.put(`${this.url}/${model.privacyTypeId}`, model);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  deleteMultiple(ids: number[]) {
    return this.http.post(`${this.url}/many-delete-task`, ids);
  }

  getAll(): Observable<ITask[]> {
    return this.http.get<ITask[]>(this.url);
  }

  getAllTask(model: ITaskRequest): Observable<ITaskResponse> {
    return this.http.post<ITaskResponse>(this.getTasksUrl, model);
  }

  get(id: number): Observable<ITask> {
    return this.http.get<ITask>(`${this.url}/${id}`);
  }

  addImages(model: ITaskImage) {
    return this.http.post(this.AddImageurl, model);
  }

  getImagesByTaskId(id: number) {
    return this.http.get<ITaskImage[]>(`${this.getImageurl}/${id}`);
  }

  deleteImagesByImageId(model: DeleteModel) {
    return this.http.post(this.deleteImageurl, model);
  }

  getSearchDetails(keyword: string): Observable<ISearchResult[]> {
    return this.http.get<ISearchResult[]>(`${this.url}/GetSearchDetails?searchKeyword=${keyword}`);
  }
  getInvoiceByTaskId(userId: number, taskId: number): Observable<number> {
    return this.http.get<number>(`${this.getInvoiceByTaskIdUrl}/${userId}/${taskId}`);
  }
  getTracking(id: number): Observable<ITaskTrackResponse[]> {
    return this.http.get<ITaskTrackResponse[]>(`${this.taskTrackingUrl}?TaskId=${id}`);
  }

  getTaskTracking(id: number): Observable<IGetTaskTrack[]> {
    return this.http.get<IGetTaskTrack[]>(`${this.taslTrackingUrl}?TaskId=${id}`);
  }
  CheckTaskCreatedBySameUser(userId: number, taskId:number): Observable<boolean> {
    return this.http.get<boolean>(`${this.checkTaskCreatedBySameUserUrl}?userId=${userId}&taskId=${taskId}`);
  }
  
  getTaskByTaskId(taskId: number): Observable<ITask> {
    return this.http.get<ITask>(`${this.getTaskById}/${taskId}`);
  }

}
